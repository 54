import React from "react";
import ToolsAndFeaturesImage from "../assets/tools-features-hero-image.png";

const ToolsAndFeaturesHero = () => {
  return (
    <div className="w-full relative">
      <img
        src={ToolsAndFeaturesImage}
        className="absolute w-full -z-[10]"
        alt="Students and workers interacting with screens"
      />
      <div className="max-w-[85rem] px-4 sm:px-6 lg:px-8 py-8 mx-auto">
        <div className="flex flex-col md:w-9/12 w-[75%] -mt-6 min-[550px]:mt-6 md:w-[60%] mx-auto md:mt-4 lg:mt-18 xl:mt-36 mb-0 lg:mb-16">
          <h1 className="w-full mx-auto mb-3 text-lg md:text-4xl font-medium text-center lg:text-5xl text-white md:text-4xl md:leading-tight">
            Bring the power of AI into your student life cycle
          </h1>
          <p className="md:mt-6 text-sm md:text-base lg:text-xl w-full text-center text-white">
            Campus staff and students can find what they need when they need it.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ToolsAndFeaturesHero;
