import React from "react";
import greymatterRetain from "../assets/greymatter_retain.png";

const ImageAccordion = () => {
  return (
    <div className="max-w-[85rem] px-2 mt-6 sm:px-6 lg:px-8 mx-auto">
      <div className="flex flex-col mx-auto lg:relative">
        <img
          src={greymatterRetain}
          alt="Laptop screen showing the greymatter CRM"
          className="mx-auto rounded-md md:w-10/12 lg:absolute lg:top-24 lg:left-16 lg:z-10 lg:w-[45%]"
        />
        <div className="mt-10 md:mt-16 md:w-11/12 md:mx-auto lg:w-[55%] h-full lg:mx-auto lg:mr-2 bg-orange-50 rounded-md p-4 md:mt-4 lg:px-14 lg:pt-12 lg:pb-8">
          <h1 className="mx-auto mt-2 text-3xl font-bold text-center lg:text-3xl lg:text-left lg:w-10/12 lg:mt-4 text-slate-800">
            greymatter delivers the whole picture
          </h1>
          <div className="p-4 m-4 bg-white rounded-sm hs-accordion-group lg:m-10 lg:mt-8 lg:px-10 lg:py-6">
            <div
              className="hs-accordion active"
              id="hs-basic-with-arrow-heading-one"
            >
              <button
                className="inline-flex items-center w-full py-3 font-semibold text-left text-gray-800 transition hs-accordion-toggle hs-accordion-active:text-red-600 group gap-x-3 hover:text-gray-500"
                aria-controls="hs-basic-with-arrow-collapse-one"
              >
                <svg
                  className="block w-3 h-3 hs-accordion-active:hidden hs-accordion-active:text-grey-800 hs-accordion-active:group-hover:text-grey-800 text-grey-800 group-hover:text-gray-500"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2 5L8.16086 10.6869C8.35239 10.8637 8.64761 10.8637 8.83914 10.6869L15 5"
                    stroke="#1F2937"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
                <svg
                  className="hidden w-3 h-3 text-gray-800 hs-accordion-active:block hs-accordion-active:text-slate-800 hs-accordion-active:group-hover:text-red-600 group-hover:text-gray-500"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2 11L8.16086 5.31305C8.35239 5.13625 8.64761 5.13625 8.83914 5.31305L15 11"
                    stroke="#1F2937"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
                <p className="font-medium">For Recruiters and marketers</p>
              </button>
              <div
                id="hs-basic-with-arrow-collapse-one"
                className="hs-accordion-content w-full overflow-hidden transition-[height] duration-300"
                aria-labelledby="hs-basic-with-arrow-heading-one"
              >
                <p className="text-zinc-500">
                  Our AI engine and scoring lets you focus on those with the
                  highest likelihood of converting.
                </p>
              </div>
            </div>
            <div className="my-4 border-b border-gray-200" />

            <div className="hs-accordion" id="hs-basic-with-arrow-heading-two">
              <button
                className="inline-flex items-center w-full py-3 font-semibold text-left text-gray-800 transition hs-accordion-toggle hs-accordion-active:text-red-600 group gap-x-3 hover:text-gray-500"
                aria-controls="hs-basic-with-arrow-collapse-two"
              >
                <svg
                  className="block w-3 h-3 text-gray-600 hs-accordion-active:hidden hs-accordion-active:text-red-600 hs-accordion-active:group-hover:text-red-600 group-hover:text-gray-500"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2 5L8.16086 10.6869C8.35239 10.8637 8.64761 10.8637 8.83914 10.6869L15 5"
                    stroke="#1F2937"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
                <svg
                  className="hidden w-3 h-3 text-gray-600 hs-accordion-active:block hs-accordion-active:text-red-600 hs-accordion-active:group-hover:text-red-600 group-hover:text-gray-500"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2 11L8.16086 5.31305C8.35239 5.13625 8.64761 5.13625 8.83914 5.31305L15 11"
                    stroke="#1F2937"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
                <p className="font-medium">
                  For admissions and Office of the Registrar
                </p>
              </button>
              <div
                id="hs-basic-with-arrow-collapse-two"
                className="hs-accordion-content hidden w-full overflow-hidden transition-[height] duration-300"
                aria-labelledby="hs-basic-with-arrow-heading-two"
              >
                <p className="text-zinc-500">
                  Manage, track, review and evaluate candidates from accepting
                  and processing online applications to enrolment.
                </p>
              </div>
            </div>
            <div className="my-4 border-b border-gray-200" />

            <div
              className="hs-accordion"
              id="hs-basic-with-arrow-heading-three"
            >
              <button
                className="inline-flex items-center w-full py-3 font-semibold text-left text-gray-800 transition hs-accordion-toggle hs-accordion-active:text-red-600 group gap-x-3 hover:text-gray-500"
                aria-controls="hs-basic-with-arrow-collapse-three"
              >
                <svg
                  className="block w-3 h-3 text-gray-600 hs-accordion-active:hidden hs-accordion-active:text-red-600 hs-accordion-active:group-hover:text-red-600 group-hover:text-gray-500"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2 5L8.16086 10.6869C8.35239 10.8637 8.64761 10.8637 8.83914 10.6869L15 5"
                    stroke="#1F2937"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
                <svg
                  className="hidden w-3 h-3 text-gray-600 hs-accordion-active:block hs-accordion-active:text-red-600 hs-accordion-active:group-hover:text-red-600 group-hover:text-gray-500"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2 11L8.16086 5.31305C8.35239 5.13625 8.64761 5.13625 8.83914 5.31305L15 11"
                    stroke="#1F2937"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
                <p className="font-medium">For advisors and counselors</p>
              </button>
              <div
                id="hs-basic-with-arrow-collapse-three"
                className="hs-accordion-content hidden w-full overflow-hidden transition-[height] duration-300"
                aria-labelledby="hs-basic-with-arrow-heading-three"
              >
                <p className="text-zinc-500">
                  Identify struggling students, then track and manage
                  interventions like tutoring programs, counseling sessions and
                  academic supports.
                </p>
              </div>
            </div>
            <div className="my-4 border-b border-gray-200" />

            <div className="hs-accordion" id="hs-basic-with-arrow-heading-four">
              <button
                className="inline-flex items-center w-full py-3 font-semibold text-left text-gray-800 transition hs-accordion-toggle hs-accordion-active:text-red-600 group gap-x-3 hover:text-gray-500"
                aria-controls="hs-basic-with-arrow-collapse-four"
              >
                <svg
                  className="block w-3 h-3 text-gray-600 hs-accordion-active:hidden hs-accordion-active:text-red-600 hs-accordion-active:group-hover:text-red-600 group-hover:text-gray-500"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2 5L8.16086 10.6869C8.35239 10.8637 8.64761 10.8637 8.83914 10.6869L15 5"
                    stroke="#1F2937"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
                <svg
                  className="hidden w-3 h-3 text-gray-600 hs-accordion-active:block hs-accordion-active:text-red-600 hs-accordion-active:group-hover:text-red-600 group-hover:text-gray-500"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2 11L8.16086 5.31305C8.35239 5.13625 8.64761 5.13625 8.83914 5.31305L15 11"
                    stroke="#1F2937"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
                <p className="font-medium">For IT staff</p>
              </button>
              <div
                id="hs-basic-with-arrow-collapse-four"
                className="hs-accordion-content hidden w-full overflow-hidden transition-[height] duration-300"
                aria-labelledby="hs-basic-with-arrow-heading-four"
              >
                <p className="text-zinc-500">
                  Work with what you know. greymatter is built on Microsoft
                  Dynamics 365 and integrates with the entire Microsoft stack
                  and your existing systems. 
                </p>
              </div>
            </div>
            <div className="my-4 border-b border-gray-200" />

            <div className="hs-accordion" id="hs-basic-with-arrow-heading-five">
              <button
                className="inline-flex items-center w-full py-3 font-semibold text-left text-gray-800 transition hs-accordion-toggle hs-accordion-active:text-red-600 group gap-x-3 hover:text-gray-500"
                aria-controls="hs-basic-with-arrow-collapse-five"
              >
                <svg
                  className="block w-3 h-3 text-gray-600 hs-accordion-active:hidden hs-accordion-active:text-red-600 hs-accordion-active:group-hover:text-red-600 group-hover:text-gray-500"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2 5L8.16086 10.6869C8.35239 10.8637 8.64761 10.8637 8.83914 10.6869L15 5"
                    stroke="#1F2937"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
                <svg
                  className="hidden w-3 h-3 text-gray-600 hs-accordion-active:block hs-accordion-active:text-red-600 hs-accordion-active:group-hover:text-red-600 group-hover:text-gray-500"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2 11L8.16086 5.31305C8.35239 5.13625 8.64761 5.13625 8.83914 5.31305L15 11"
                    stroke="#1F2937"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
                <p className="font-medium">For higher-education leaders</p>
              </button>
              <div
                id="hs-basic-with-arrow-collapse-five"
                className="hs-accordion-content hidden w-full overflow-hidden transition-[height] duration-300"
                aria-labelledby="hs-basic-with-arrow-heading-five"
              >
                <p className="text-zinc-500">
                  greymatter’s data, analytics and automation help you make
                  informed, strategic decisions. These tools also boost
                  efficiency by letting staff focus on high-value tasks.
                </p>
              </div>
            </div>
          </div>

          <div className="flex ml-4 lg:ml-10">
            <div className="mt-4 mb-8 lg:mt-10">
              <button
                type="button"
                className="inline-flex items-center justify-center gap-2 px-4 py-3 text-sm font-semibold text-white transition-all bg-blue-500 border border-transparent rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                data-hs-overlay="#contact-sales-modal"
              >
                Request a demo
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageAccordion;
