import React from "react";
import ToolsAndFeaturesHero from "../components/ToolsAndFeaturesHero";
import NewAndNoteworthy from "../components/NewAndNoteworthy";
import Stages from "../components/Stages";
import PromoTextCallout from "../components/PromoTextCallout";

const ToolsAndFeatures = () => {
  return (
    <div className="tools-and-features relative">
      <ToolsAndFeaturesHero />
      <NewAndNoteworthy />
      <Stages />
      <PromoTextCallout />
    </div>
  );
};

export default ToolsAndFeatures;
