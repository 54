import React from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import AnnouncementBar from "./AnnouncementBar";
import Logo from "../assets/Logo.png";

const Navigation = () => {
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");

  return (
    <div>
      <header className="z-50 flex flex-wrap w-full py-3 text-sm text-white bg-black sm:justify-start sm:flex-nowrap sm:py-0">
        <nav
          className="relative max-w-full mx-4 w-[90%] md:w-full sm:flex sm:items-center sm:justify-between"
          aria-label="Global"
        >
          <div className="flex items-center justify-between mb-[7px]">
            <Link to="/">
              <span className="flex-none" aria-label="Logo">
                <img className="h-14" src={Logo} alt="Logo" />
              </span>
            </Link>
            <div className="sm:hidden">
              <button
                type="button"
                className="inline-flex items-center justify-center gap-2 p-2 text-sm font-medium text-gray-700 align-middle transition-all bg-white border rounded-md shadow-sm hs-collapse-toggle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 dark:bg-slate-900 dark:hover:bg-slate-800 dark:border-gray-700 dark:text-gray-400 dark:hover:text-white dark:focus:ring-offset-gray-800"
                data-hs-collapse="#navbar-collapse-with-animation"
                aria-controls="navbar-collapse-with-animation"
                aria-label="Toggle navigation"
              >
                <svg
                  className="w-4 h-4 hs-collapse-open:hidden"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
                  />
                </svg>
                <svg
                  className="hidden w-4 h-4 hs-collapse-open:block"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                >
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                </svg>
              </button>
            </div>
          </div>
          <div
            id="navbar-collapse-with-animation"
            className="hidden overflow-hidden transition-all duration-300 hs-collapse basis-full grow sm:block"
          >
            <div className="flex flex-col mt-5 ml-4 gap-y-4 gap-x-0 sm:flex-row sm:items-center sm:gap-y-0 sm:gap-x-7 md:gap-x-4 sm:mt-0 sm:pl-7">
              <Link
                to="/"
                className={
                  splitLocation[1] === "" ? "navlink-active" : "navlink"
                }
              >
                Product
              </Link>
              <Link
                to="/tools-and-features"
                className={
                  splitLocation[1] === "tools-and-features"
                    ? "navlink-active"
                    : "navlink"
                }
              >
                Tools + Features
              </Link>
              <div className="flex flex-col sm:flex-row sm:items-center gap-x-2 gap-y-4 sm:gap-x-7 sm:mt-0 sm:pl-7 sm:ml-auto">
                <span className="font-normal text-white sm:py-6">
                  For Pricing or a Demo
                </span>

                <button
                  type="button"
                  className="text-gray-900 py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all text-sm hover:bg-gray-200"
                  data-hs-overlay="#contact-sales-modal"
                >
                  Contact Sales
                </button>
              </div>
            </div>
          </div>
        </nav>
      </header>
      <AnnouncementBar />
    </div>
  );
};

export default Navigation;
