import React from "react";
import CardOne from "../assets/card-one.jpg";
import CardTwo from "../assets/card-two.jpg";
import CardThree from "../assets/card-three.png";

const CardsSection = () => {
  return (
    <div className="max-w-[85rem] mt-10 px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
      <div className="mx-auto text-center mb-10 lg:mb-14">
        <div className="mb-6 flex flex-row items-center">
          <hr className="w-3/4 md:w-full border-b border-[#C32027] font-light" />
          <p className="w-full md:w-[35%] text-[#C32027] font-medium bg-white">
            What is greymatter?
          </p>
          <hr className="w-3/4 md:w-full border-b border-[#C32027] font-light" />
        </div>

        <div className="w-10/12 lg:w-[55%] mx-auto">
          <h1 className="text-2xl w-full sm:w-6/12 md:w-11/12 mx-auto sm:text-4xl text-center font-bold text-slate-800">
            Powerful tools deliver powerful results
          </h1>
          <p className="mt-4 lg:mt-4 text-base text-[#64748B]">
            greymatter is a full life cycle higher-education CRM. It delivers a
            360-degree view of the student experience, automates everyday,
            repetitive tasks and integrates with leading higher-education
            systems and platforms.
          </p>
        </div>
      </div>

      <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-10">
        <div className="group flex flex-col h-full border border-[#E5E7EB]">
          <div className="aspect-w-16 aspect-h-9">
            <img
              className="w-full object-fit h-[200px]"
              src={CardOne}
              alt="Red and orange background with abstract shapes"
            />
          </div>
          <div className="p-5">
            <h3 className="text-lg font-semibold text-slate-800">
              AI-driven insights and nudges unlock student potential
            </h3>
            <p className="mt-4 text-[#71717A] text-base">
              Our AI engine continuously analyzes data to optimize student
              experiences. It helps identify students who are struggling and
              facilitates personal interactions with advisors.
            </p>
          </div>
        </div>
        <div className="group flex flex-col h-full border border-[#E5E7EB]">
          <div className="aspect-w-16 aspect-h-9">
            <img
              className="w-full object-fit h-[200px]"
              src={CardTwo}
              alt="People in a classroom threatre setting"
            />
          </div>
          <div className="p-5">
            <h3 className="text-lg font-semibold text-slate-800">
              1 campus. 0 data silos.
            </h3>
            <p className="mt-4 text-[#71717A] text-base">
              greymatter’s holistic data model, integration capability and
              Microsoft interoperability mean no more systems that can’t talk to
              each other. No more data silos mean no more duplication of effort.
            </p>
          </div>
        </div>
        <div className="group flex flex-col h-full border border-[#E5E7EB]">
          <div className="aspect-w-16 aspect-h-9">
            <img
              className="w-full object-fit h-[200px]"
              src={CardThree}
              alt="Microsoft Dynamics 365 logo"
            />
          </div>
          <div className="p-5">
            <h3 className="text-lg font-semibold text-slate-800">
              Built on Microsoft Dynamics 365 for ease of integration and
              efficient workflows
            </h3>
            <p className="mt-4 text-[#71717A] text-base">
              greymatter is built on an enterprise-grade Dynamic 365 platform.
              Seamless integration with Robotic Process Automation and
              Microsoft’s Power Automate allows for automated workflows across
              most apps and services.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardsSection;
