import React from "react";
import { Link } from "react-router-dom";
import LifecycleRecruit from "../assets/lifecycle-recruit.png";
import LifecycleAdmit from "../assets/lifecycle-admit.png";
import LifecycleRetain from "../assets/lifecycle-retain.png";
import LifecyclePromote from "../assets/lifecycle-promote.png";
import Modal from "./Modal";
import ModalButton from "./ModalButton";
import VideoPlayer from "./VideoPlayer";

const Tabs = () => {
  return (
    <div className="max-w-[85rem] px-4 sm:px-6 lg:px-8 mx-auto mt-20 md:pl-8 mb-4">
      <div className="mx-auto sm:w-full md:w-1/2">
        <nav
          className="-mb-0.5 flex justify-center"
          aria-label="Tabs"
          role="tablist"
        >
          <button
            type="button"
            className="w-[89px] rounded-s hs-tab-active:font-semibold hs-tab-active:border-[2px] hs-tab-active:border-blue-500 hs-tab-active:text-blue-500 py-4 inline-flex items-center justify-center border-l-[2px] border-t-[2px] border-b-[2px] border-grey-200 text-sm whitespace-nowrap text-gray-500 hover:text-blue-500 active"
            id="horizontal-alignment-item-1"
            data-hs-tab="#horizontal-alignment-1"
            aria-controls="horizontal-alignment-1"
            role="tab"
          >
            Recruit
          </button>
          <button
            type="button"
            className="w-[89px] hs-tab-active:font-semibold hs-tab-active:border-[2px] hs-tab-active:border-blue-500 hs-tab-active:text-blue-500 py-4 inline-flex items-center justify-center border-t-[2px] border-b-[2px] border-l-[2px] border-grey-200 text-sm whitespace-nowrap text-gray-500 hover:text-blue-500"
            id="horizontal-alignment-item-2"
            data-hs-tab="#horizontal-alignment-2"
            aria-controls="horizontal-alignment-2"
            role="tab"
          >
            Admit
          </button>
          <button
            type="button"
            className="w-[89px] hs-tab-active:font-semibold hs-tab-active:border-[2px] hs-tab-active:border-blue-500 hs-tab-active:text-blue-500 py-4 inline-flex items-center justify-center border-t-[2px] border-b-[2px] border-l-[2px] border-grey-200 text-sm whitespace-nowrap text-gray-500 hover:text-blue-500"
            id="horizontal-alignment-item-3"
            data-hs-tab="#horizontal-alignment-3"
            aria-controls="horizontal-alignment-3"
            role="tab"
          >
            Retain
          </button>
          <button
            type="button"
            className="w-[89px] hs-tab-active:font-semibold hs-tab-active:border-blue-500 hs-tab-active:border-[2px] hs-tab-active:text-blue-500 py-4 inline-flex items-center justify-center border-[2px] border-grey-200 text-sm whitespace-nowrap text-gray-500 hover:text-blue-500"
            id="horizontal-alignment-item-4"
            data-hs-tab="#horizontal-alignment-4"
            aria-controls="horizontal-alignment-4"
            role="tab"
          >
            Promote
          </button>
        </nav>
      </div>

      {/* --------------------- TAB CONTENT --------------------- */}

      <div className="px-8 mt-10 border shadow-lg">
        {/* TAB ONE CONTENT */}
        <div
          id="horizontal-alignment-1"
          role="tabpanel"
          aria-labelledby="horizontal-alignment-item-1"
        >
          <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
            <div className="grid gap-12 md:grid-cols-2">
              <div className="lg:w-3/4">
                <h2 className="text-3xl font-bold text-slate-800 lg:text-4xl">
                  Guide prospects with automated, intelligent and personalized
                  journeys
                </h2>
                <p className="mt-3 text-[#71717A] font-normal text-base">
                  It starts with data and powerful analytics to determine
                  preferences and patterns. Once you understand what a
                  prospective students need, you can tailor the journey and
                  provide nudges that deliver results.
                </p>

                <ModalButton id="tab-recruit-modal" />
                <Modal id="tab-recruit-modal">
                  <VideoPlayer url="https://youtu.be/tnjMd1MWV50?si=QMe7QEEOzp5LzCef" />
                </Modal>

                <img
                  src={LifecycleRecruit}
                  alt="Recruit stage of greymatter lifecycle"
                  className="w-[100%] my-10"
                />
              </div>

              <div className="space-y-6 lg:space-y-10">
                <div className="flex flex-col">
                  <hr className="border-b-[1px] border-gray-200 w-11/12 mt-2" />
                  <h3 className="w-full mt-10 text-base font-semibold text-gray-500 sm:text-lg">
                    Tools, features and capabilities to help staff and students
                    succeed
                  </h3>
                </div>
                <div className="flex">
                  <div className="mt-4 ml-5 sm:ml-8">
                    <h3 className="text-base font-bold sm:text-lg text-slate-800">
                      AI-based chatbots
                    </h3>
                    <p className="mt-1 text-[#71717A] text-base font-normal md:w-10/12">
                      Potential students have questions. They can get answers to
                      frequently asked questions 24/7 with our generative
                      AI-based chatbot.
                    </p>
                    <hr className="border-b-[1px] w-11/12 border-gray-200 mt-16" />
                  </div>
                </div>
                <div className="flex">
                  <div className="mt-4 ml-5 sm:ml-8">
                    <h3 className="text-base font-bold sm:text-lg text-slate-800">
                      Communicate effectively with prospects
                    </h3>
                    <p className="mt-1 text-[#71717A] text-base font-normal md:w-10/12">
                      Reach students through multiple channels, including phone,
                      SMS, live web chat, and social media.  
                    </p>
                    <hr className="border-b-[1px] w-11/12 border-gray-200 mt-16" />
                  </div>
                </div>
                <div className="flex">
                  <div className="mt-4 ml-5 sm:ml-8">
                    <h3 className="text-base font-bold sm:text-lg text-slate-800">
                      A portal for every type of user
                    </h3>
                    <p className="mt-1 text-[#71717A] text-base font-normal md:w-10/12">
                      greymatter makes it easy to create portals for prospective
                      students, recruitment agencies, college readiness partners
                      and high-school counselors.
                    </p>
                  </div>
                </div>
                <div className="flex">
                  <div className="my-8">
                    <Link to="/tools-and-features">
                      <button
                        type="button"
                        className="inline-flex items-center justify-center gap-2 px-4 py-3 text-sm font-semibold text-white transition-all bg-blue-500 border border-transparent rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                      >
                        Discover all Tools + Features
                        <svg
                          className="w-2.5 h-auto"
                          width="17"
                          height="16"
                          viewBox="0 0 17 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M1 7C0.447715 7 -3.73832e-07 7.44771 -3.49691e-07 8C-3.2555e-07 8.55228 0.447715 9 1 9L13.0858 9L7.79289 14.2929C7.40237 14.6834 7.40237 15.3166 7.79289 15.7071C8.18342 16.0976 8.81658 16.0976 9.20711 15.7071L16.0303 8.88388C16.5185 8.39573 16.5185 7.60427 16.0303 7.11612L9.20711 0.292893C8.81658 -0.0976318 8.18342 -0.0976318 7.79289 0.292893C7.40237 0.683417 7.40237 1.31658 7.79289 1.70711L13.0858 7L1 7Z"
                            fill="currentColor"
                          />
                        </svg>
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* TAB TWO CONTENT */}
        <div
          id="horizontal-alignment-2"
          className="hidden"
          role="tabpanel"
          aria-labelledby="horizontal-alignment-item-2"
        >
          <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
            <div className="grid gap-12 md:grid-cols-2">
              <div className="lg:w-3/4">
                <h2 className="text-3xl font-bold text-slate-800 lg:text-4xl">
                  Streamline your admissions process
                </h2>
                <p className="mt-3 text-[#71717A] font-normal text-base">
                  Data collected in recruitment informs a tailored application
                  process. Provide AI-driven multi-channel nudges to ensure that
                  all required checklist requirements are fulfilled.
                </p>

                <ModalButton id="tab-admit-modal" />
                <Modal id="tab-admit-modal">
                  <VideoPlayer url="https://youtu.be/pitl007DAE8?si=S-kW21kquQHgn1si" />
                </Modal>

                <img
                  src={LifecycleAdmit}
                  alt="Admit stage of greymatter lifecycle"
                  className="w-[100%] my-10"
                />
              </div>

              <div className="space-y-6 lg:space-y-10">
                <div className="flex flex-col">
                  <hr className="border-b-[1px] border-gray-200 w-11/12 mt-2" />
                  <h3 className="w-full mt-10 text-base font-semibold text-gray-500 sm:text-lg">
                    Tools, features and capabilities to help staff and students
                    succeed
                  </h3>
                </div>
                <div className="flex">
                  <div className="mt-4 ml-5 sm:ml-8">
                    <h3 className="text-base font-bold sm:text-lg text-slate-800">
                      Leverage your Student Information System
                    </h3>
                    <p className="mt-1 text-[#71717A] text-base font-normal md:w-10/12">
                      greymatter meshes seamlessly with leading Student
                      Information Systems, letting you easily manage admission
                      offers as well as acceptance communications and processes.
                    </p>
                    <hr className="border-b-[1px] w-11/12 border-gray-200 mt-16" />
                  </div>
                </div>
                <div className="flex">
                  <div className="mt-4 ml-5 sm:ml-8">
                    <h3 className="text-base font-bold sm:text-lg text-slate-800">
                      Create and view student profiles
                    </h3>
                    <p className="mt-1 text-[#71717A] text-base font-normal md:w-10/12">
                      Support staff can easily track applicants throughout the
                      admissions process. This means prospective students get
                      the answers they need and staff can work with maximum
                      efficiency.
                    </p>
                    <hr className="border-b-[1px] w-11/12 border-gray-200 mt-16" />
                  </div>
                </div>
                <div className="flex">
                  <div className="mt-4 ml-5 sm:ml-8">
                    <h3 className="text-base font-bold sm:text-lg text-slate-800">
                      You’re in control
                    </h3>
                    <p className="mt-1 text-[#71717A] text-base font-normal md:w-10/12">
                      Powerful features make everyone’s life easier. greymatter
                      lets you determine course capacities, score applicants for
                      limited programs, update course histories and more.
                    </p>
                  </div>
                </div>
                <div className="flex">
                  <div className="my-8">
                    <Link to="/tools-and-features">
                      <button
                        type="button"
                        className="inline-flex items-center justify-center gap-2 px-4 py-3 text-sm font-semibold text-white transition-all bg-blue-500 border border-transparent rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                      >
                        Discover all Tools + Features
                        <svg
                          className="w-2.5 h-auto"
                          width="17"
                          height="16"
                          viewBox="0 0 17 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M1 7C0.447715 7 -3.73832e-07 7.44771 -3.49691e-07 8C-3.2555e-07 8.55228 0.447715 9 1 9L13.0858 9L7.79289 14.2929C7.40237 14.6834 7.40237 15.3166 7.79289 15.7071C8.18342 16.0976 8.81658 16.0976 9.20711 15.7071L16.0303 8.88388C16.5185 8.39573 16.5185 7.60427 16.0303 7.11612L9.20711 0.292893C8.81658 -0.0976318 8.18342 -0.0976318 7.79289 0.292893C7.40237 0.683417 7.40237 1.31658 7.79289 1.70711L13.0858 7L1 7Z"
                            fill="currentColor"
                          />
                        </svg>
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* TAB THREE CONTENT */}
        <div
          id="horizontal-alignment-3"
          className="hidden"
          role="tabpanel"
          aria-labelledby="horizontal-alignment-item-3"
        >
          <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
            <div className="grid gap-12 md:grid-cols-2">
              <div className="lg:w-3/4">
                <h2 className="text-3xl font-bold text-slate-800 lg:text-4xl">
                  When students excel, everyone wins
                </h2>
                <p className="mt-3 text-[#71717A] font-normal text-base">
                  Give your advisors the tools and insights they need to
                  identify students who need a little help and then address the
                  barriers to educational attainment they face.
                </p>

                <ModalButton id="tab-retain-modal" />
                <Modal id="tab-retain-modal">
                  <VideoPlayer url="https://www.youtube.com/watch?v=l1UPlVAywvM" />
                </Modal>

                <img
                  src={LifecycleRetain}
                  alt="Recruit stage of greymatter lifecycle"
                  className="w-[100%] my-10"
                />
              </div>

              <div className="space-y-6 lg:space-y-10">
                <div className="flex flex-col">
                  <hr className="border-b-[1px] border-gray-200 w-11/12 mt-2" />
                  <h3 className="w-full mt-10 text-base font-semibold text-gray-500 sm:text-lg">
                    Tools, features and capabilities to help staff and students
                    succeed
                  </h3>
                </div>
                <div className="flex">
                  <div className="mt-4 ml-5 sm:ml-8">
                    <h3 className="text-base font-bold sm:text-lg text-slate-800">
                      Centralize student data
                    </h3>
                    <p className="mt-1 text-[#71717A] text-base font-normal md:w-10/12">
                      Bring together data from your Learning Management and
                      Student Information Systems to empower degree planning,
                      alerting, management of risks and advising.
                    </p>
                    <hr className="border-b-[1px] w-11/12 border-gray-200 mt-16" />
                  </div>
                </div>
                <div className="flex">
                  <div className="mt-4 ml-5 sm:ml-8">
                    <h3 className="text-base font-bold sm:text-lg text-slate-800">
                      Get a handle on the student journey
                    </h3>
                    <p className="mt-1 text-[#71717A] text-base font-normal md:w-10/12">
                      greymatter delivers comprehensive degree planning, guided
                      pathways, academic progression and degree audit
                      functionality.
                    </p>
                    <hr className="border-b-[1px] w-11/12 border-gray-200 mt-16" />
                  </div>
                </div>
                <div className="flex">
                  <div className="mt-4 ml-5 sm:ml-8">
                    <h3 className="text-base font-bold sm:text-lg text-slate-800">
                      Take care of the details
                    </h3>
                    <p className="mt-1 text-[#71717A] text-base font-normal md:w-10/12">
                      greymatter lets you handle queuing, virtual and in-person
                      appointments, and resource scheduling with ease.
                    </p>
                  </div>
                </div>
                <div className="flex">
                  <div className="my-8">
                    <Link to="/tools-and-features">
                      <button
                        type="button"
                        className="inline-flex items-center justify-center gap-2 px-4 py-3 text-sm font-semibold text-white transition-all bg-blue-500 border border-transparent rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                      >
                        Discover all Tools + Features
                        <svg
                          className="w-2.5 h-auto"
                          width="17"
                          height="16"
                          viewBox="0 0 17 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M1 7C0.447715 7 -3.73832e-07 7.44771 -3.49691e-07 8C-3.2555e-07 8.55228 0.447715 9 1 9L13.0858 9L7.79289 14.2929C7.40237 14.6834 7.40237 15.3166 7.79289 15.7071C8.18342 16.0976 8.81658 16.0976 9.20711 15.7071L16.0303 8.88388C16.5185 8.39573 16.5185 7.60427 16.0303 7.11612L9.20711 0.292893C8.81658 -0.0976318 8.18342 -0.0976318 7.79289 0.292893C7.40237 0.683417 7.40237 1.31658 7.79289 1.70711L13.0858 7L1 7Z"
                            fill="currentColor"
                          />
                        </svg>
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* TAB FOUR CONTENT */}
        <div
          id="horizontal-alignment-4"
          className="hidden"
          role="tabpanel"
          aria-labelledby="horizontal-alignment-item-4"
        >
          <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
            <div className="grid gap-12 md:grid-cols-2">
              <div className="lg:w-3/4">
                <h2 className="text-3xl font-bold text-slate-800 lg:text-4xl">
                  Let everyone know just how good you are
                </h2>
                <p className="mt-3 text-[#71717A] font-normal text-base">
                  Rely on greymatter to promote your institution’s offerings and
                  grow a diversified revenue base. Our Promote functionality
                  makes life easier for your continuing education and corporate
                  sales teams.
                </p>
                <ModalButton id="tab-promote-modal" />
                <Modal id="tab-promote-modal">
                  <VideoPlayer url="https://www.youtube.com/watch?v=2YA3B5Ganac" />
                </Modal>

                <img
                  src={LifecyclePromote}
                  alt="Recruit stage of greymatter lifecycle"
                  className="w-[100%] my-10"
                />
              </div>

              <div className="space-y-6 lg:space-y-10">
                <div className="flex flex-col">
                  <hr className="border-b-[1px] border-gray-200 w-11/12 mt-2" />
                  <h3 className="w-full mt-10 text-base font-semibold text-gray-500 sm:text-lg">
                    Tools, features and capabilities to help staff and students
                    succeed
                  </h3>
                </div>
                <div className="flex">
                  <div className="mt-4 ml-5 sm:ml-8">
                    <h3 className="text-base font-bold sm:text-lg text-slate-800">
                      Engaging e-commerce experiences
                    </h3>
                    <p className="mt-1 text-[#71717A] text-base font-normal md:w-10/12">
                      Facilitate online registration right down to discounting
                      and invoicing for corporate partners. greymatter is
                      designed to ensure great experiences—and conversions.
                    </p>
                    <hr className="border-b-[1px] w-11/12 border-gray-200 mt-16" />
                  </div>
                </div>
                <div className="flex">
                  <div className="mt-4 ml-5 sm:ml-8">
                    <h3 className="text-base font-bold sm:text-lg text-slate-800">
                      Robust financial tracking
                    </h3>
                    <p className="mt-1 text-[#71717A] text-base font-normal md:w-10/12">
                      Keeps an eye on things with greymatter’s end-to-end
                      financial tracking. It Integrates with your financial
                      systems to give administrators the whole picture.
                    </p>
                    <hr className="border-b-[1px] w-11/12 border-gray-200 mt-16" />
                  </div>
                </div>
                <div className="flex">
                  <div className="mt-4 ml-5 sm:ml-8">
                    <h3 className="text-base font-bold sm:text-lg text-slate-800">
                      Sales automation
                    </h3>
                    <p className="mt-1 text-[#71717A] text-base font-normal md:w-10/12">
                      Sales teams can leverage greymatter to take clients from
                      leads all the way through opportunity, quote, contract and
                      invoicing. 
                    </p>
                  </div>
                </div>
                <div className="flex">
                  <div className="my-8">
                    <Link to="/tools-and-features">
                      <button
                        type="button"
                        className="inline-flex items-center justify-center gap-2 px-4 py-3 text-sm font-semibold text-white transition-all bg-blue-500 border border-transparent rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                      >
                        Discover all Tools + Features
                        <svg
                          className="w-2.5 h-auto"
                          width="17"
                          height="16"
                          viewBox="0 0 17 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M1 7C0.447715 7 -3.73832e-07 7.44771 -3.49691e-07 8C-3.2555e-07 8.55228 0.447715 9 1 9L13.0858 9L7.79289 14.2929C7.40237 14.6834 7.40237 15.3166 7.79289 15.7071C8.18342 16.0976 8.81658 16.0976 9.20711 15.7071L16.0303 8.88388C16.5185 8.39573 16.5185 7.60427 16.0303 7.11612L9.20711 0.292893C8.81658 -0.0976318 8.18342 -0.0976318 7.79289 0.292893C7.40237 0.683417 7.40237 1.31658 7.79289 1.70711L13.0858 7L1 7Z"
                            fill="currentColor"
                          />
                        </svg>
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tabs;
