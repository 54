import React from "react";

const TextBlockCallout = () => {
  return (
    <div className="max-w-[85rem] px-4 sm:px-6 lg:px-8 lg:py-8 mx-auto">
      <div className="flex flex-col md:w-9/12 lg:w-[60%] mx-auto mt-36 mb-16">
        <h2 className="text-2xl text-center text-slate-700 font-medium md:text-4xl md:leading-tight mb-3">
          Looking for pricing?
        </h2>
        <p className="mt-6 text-base w-full text-center text-[#64748B]">
          Our pricing varies depending on the number of users, types and
          features. The best way to get a quote or range is to discuss your
          business needs with one of account team members.
        </p>
        <button
          type="button"
          className="mx-auto mt-10 py-3 px-4 w-32 inline-flex justify-center items-center gap-2 rounded-md border-2 border-grey-200 font-semibold text-blue-500 hover:text-white hover:bg-blue-500 hover:border-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-200 focus:ring-offset-2 transition-all text-sm"
          data-hs-overlay="#contact-sales-modal"
        >
          Contact Sales
        </button>
      </div>
    </div>
  );
};

export default TextBlockCallout;
