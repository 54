import React from "react";
import Athabasca from "../assets/athabasca.png";
import ThompsonRivers from "../assets/thompson-rivers.png";
import Langara from "../assets/langara.png";

const CaseStudies = () => {
  return (
    <div className="max-w-[85rem] px-2 sm:px-6 lg:px-8 mx-auto">
      <div className="mb-6 flex flex-row items-center mx-auto mt-24 text-center">
        <hr className="w-3/4 md:w-full border-b border-[#C32027] font-light" />
        <p className="w-[75%] lg:w-[20%] text-[#C32027] font-medium bg-white">
          Case Studies
        </p>
        <hr className="w-3/4 md:w-full border-b border-[#C32027] font-light" />
      </div>
      <h1 className="text-2xl w-9/12 sm:w-6/12 mx-auto sm:text-4xl text-center font-bold text-slate-800">
        Learn how we help our customers solve real-word challenges
      </h1>

      {/* Cards */}

      <div className="grid sm:grid-cols-2 sm:gap-x-18 sm:gap-y-6 mt-20 sm:mt-24">
        {/* Card One */}
        <div className="bg-white sm:flex sm:items-center mb-8 md:mb-14">
          <div className="w-full rounded-t-xl overflow-hidden sm:rounded-l-xl sm:max-w-[15rem] md:rounded-tr-none md:max-w-xs">
            <img
              className="w-7/12 sm:w-11/12 lg:w-9/12 mx-auto object-cover"
              src={Athabasca}
              alt="Athabasca University Logo"
            />
          </div>
          <div className="flex flex-wrap">
            <div className="px-2 py-6 flex flex-col h-full sm:p-5">
              <h3 className="text-base sm:text-lg font-bold text-slate-800 leading-6">
                Athabasca University
              </h3>
              <p className="text-base mt-1 text-zinc-500 font-normal">
                AU leaned on greymatter to improve recruitment, admittance and
                retention by providing complete flexibility and accessibility to
                online students.
              </p>
              <div className="mt-6">
                <a
                  href="https://frequencyfoundry.com/case-study/athabasca-university-brings-the-world-closer-with-greymatter/"
                  target="_blank"
                  rel="noreferrer"
                  className="pointer"
                >
                  <button>
                    <p className="inline-flex items-center gap-x-2 text-sm font-normal text-[#456CCF]">
                      Read More
                      <svg
                        width="14"
                        height="15"
                        viewBox="0 0 14 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0_884_22561)">
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M7.5565 3.84033C7.5565 3.7243 7.51041 3.61302 7.42836 3.53097C7.34631 3.44893 7.23503 3.40283 7.119 3.40283H1.3125C0.964403 3.40283 0.630564 3.54111 0.384422 3.78725C0.138281 4.0334 0 4.36724 0 4.71533L0 13.4653C0 13.8134 0.138281 14.1473 0.384422 14.3934C0.630564 14.6396 0.964403 14.7778 1.3125 14.7778H10.0625C10.4106 14.7778 10.7444 14.6396 10.9906 14.3934C11.2367 14.1473 11.375 13.8134 11.375 13.4653V7.65883C11.375 7.5428 11.3289 7.43152 11.2469 7.34947C11.1648 7.26743 11.0535 7.22133 10.9375 7.22133C10.8215 7.22133 10.7102 7.26743 10.6281 7.34947C10.5461 7.43152 10.5 7.5428 10.5 7.65883V13.4653C10.5 13.5814 10.4539 13.6926 10.3719 13.7747C10.2898 13.8567 10.1785 13.9028 10.0625 13.9028H1.3125C1.19647 13.9028 1.08519 13.8567 1.00314 13.7747C0.921094 13.6926 0.875 13.5814 0.875 13.4653V4.71533C0.875 4.5993 0.921094 4.48802 1.00314 4.40597C1.08519 4.32393 1.19647 4.27783 1.3125 4.27783H7.119C7.23503 4.27783 7.34631 4.23174 7.42836 4.14969C7.51041 4.06764 7.5565 3.95636 7.5565 3.84033Z"
                            fill="#456CCF"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M14 1.21533C14 1.0993 13.9539 0.98802 13.8719 0.905973C13.7898 0.823926 13.6785 0.777832 13.5625 0.777832H9.1875C9.07147 0.777832 8.96019 0.823926 8.87814 0.905973C8.7961 0.98802 8.75 1.0993 8.75 1.21533C8.75 1.33136 8.7961 1.44264 8.87814 1.52469C8.96019 1.60674 9.07147 1.65283 9.1875 1.65283H12.5064L5.37775 8.78058C5.33708 8.82126 5.30481 8.86955 5.2828 8.9227C5.26078 8.97584 5.24945 9.03281 5.24945 9.09033C5.24945 9.14786 5.26078 9.20482 5.2828 9.25797C5.30481 9.31111 5.33708 9.35941 5.37775 9.40008C5.41843 9.44076 5.46672 9.47303 5.51987 9.49504C5.57301 9.51705 5.62998 9.52838 5.6875 9.52838C5.74503 9.52838 5.80199 9.51705 5.85514 9.49504C5.90829 9.47303 5.95658 9.44076 5.99725 9.40008L13.125 2.27146V5.59033C13.125 5.70636 13.1711 5.81764 13.2531 5.89969C13.3352 5.98174 13.4465 6.02783 13.5625 6.02783C13.6785 6.02783 13.7898 5.98174 13.8719 5.89969C13.9539 5.81764 14 5.70636 14 5.59033V1.21533Z"
                            fill="#456CCF"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_884_22561">
                            <rect
                              width="14"
                              height="14"
                              fill="white"
                              transform="translate(0 0.777832)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </p>
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>

        {/* Card Two */}
        <div className="bg-white sm:flex sm:items-center mb-14">
          <div className="w-full rounded-t-xl overflow-hidden sm:rounded-l-xl sm:max-w-[15rem] md:rounded-tr-none md:max-w-xs">
            <img
              className="w-7/12 sm:w-11/12 lg:w-9/12 mx-auto object-cover"
              src={ThompsonRivers}
              alt="Thompson Rivers University Logo"
            />
          </div>
          <div className="flex flex-wrap">
            <div className="px-2 py-6 flex flex-col h-full sm:p-5">
              <h3 className="text-base sm:text-lg font-bold text-slate-800 leading-6">
                Thompson Rivers University
              </h3>
              <p className="text-base mt-1 text-zinc-500 font-normal">
                Centralizing records, interactions and workflows let TRU break
                down silos and improve every aspect of the student journey.
              </p>
              <div className="mt-6">
                <a
                  href="https://frequencyfoundry.com/case-study/tru-transforms-business-of-higher-education-with-greymatter/"
                  target="_blank"
                  rel="noreferrer"
                  className="pointer"
                >
                  <button>
                    <p className="inline-flex items-center gap-x-2 text-sm font-normal text-[#456CCF]">
                      Read More
                      <svg
                        width="14"
                        height="15"
                        viewBox="0 0 14 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0_884_22561)">
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M7.5565 3.84033C7.5565 3.7243 7.51041 3.61302 7.42836 3.53097C7.34631 3.44893 7.23503 3.40283 7.119 3.40283H1.3125C0.964403 3.40283 0.630564 3.54111 0.384422 3.78725C0.138281 4.0334 0 4.36724 0 4.71533L0 13.4653C0 13.8134 0.138281 14.1473 0.384422 14.3934C0.630564 14.6396 0.964403 14.7778 1.3125 14.7778H10.0625C10.4106 14.7778 10.7444 14.6396 10.9906 14.3934C11.2367 14.1473 11.375 13.8134 11.375 13.4653V7.65883C11.375 7.5428 11.3289 7.43152 11.2469 7.34947C11.1648 7.26743 11.0535 7.22133 10.9375 7.22133C10.8215 7.22133 10.7102 7.26743 10.6281 7.34947C10.5461 7.43152 10.5 7.5428 10.5 7.65883V13.4653C10.5 13.5814 10.4539 13.6926 10.3719 13.7747C10.2898 13.8567 10.1785 13.9028 10.0625 13.9028H1.3125C1.19647 13.9028 1.08519 13.8567 1.00314 13.7747C0.921094 13.6926 0.875 13.5814 0.875 13.4653V4.71533C0.875 4.5993 0.921094 4.48802 1.00314 4.40597C1.08519 4.32393 1.19647 4.27783 1.3125 4.27783H7.119C7.23503 4.27783 7.34631 4.23174 7.42836 4.14969C7.51041 4.06764 7.5565 3.95636 7.5565 3.84033Z"
                            fill="#456CCF"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M14 1.21533C14 1.0993 13.9539 0.98802 13.8719 0.905973C13.7898 0.823926 13.6785 0.777832 13.5625 0.777832H9.1875C9.07147 0.777832 8.96019 0.823926 8.87814 0.905973C8.7961 0.98802 8.75 1.0993 8.75 1.21533C8.75 1.33136 8.7961 1.44264 8.87814 1.52469C8.96019 1.60674 9.07147 1.65283 9.1875 1.65283H12.5064L5.37775 8.78058C5.33708 8.82126 5.30481 8.86955 5.2828 8.9227C5.26078 8.97584 5.24945 9.03281 5.24945 9.09033C5.24945 9.14786 5.26078 9.20482 5.2828 9.25797C5.30481 9.31111 5.33708 9.35941 5.37775 9.40008C5.41843 9.44076 5.46672 9.47303 5.51987 9.49504C5.57301 9.51705 5.62998 9.52838 5.6875 9.52838C5.74503 9.52838 5.80199 9.51705 5.85514 9.49504C5.90829 9.47303 5.95658 9.44076 5.99725 9.40008L13.125 2.27146V5.59033C13.125 5.70636 13.1711 5.81764 13.2531 5.89969C13.3352 5.98174 13.4465 6.02783 13.5625 6.02783C13.6785 6.02783 13.7898 5.98174 13.8719 5.89969C13.9539 5.81764 14 5.70636 14 5.59033V1.21533Z"
                            fill="#456CCF"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_884_22561">
                            <rect
                              width="14"
                              height="14"
                              fill="white"
                              transform="translate(0 0.777832)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </p>
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Card Three */}
      <div className="bg-white sm:flex sm:items-center md:w-1/2 mx-auto">
        <div className="w-full rounded-t-xl overflow-hidden sm:rounded-l-xl sm:max-w-[15rem] md:rounded-tr-none md:max-w-xs">
          <img
            className="w-7/12 sm:w-11/12 lg:w-9/12 mx-auto object-cover"
            src={Langara}
            alt="Langara College Logo"
          />
        </div>
        <div className="flex flex-wrap">
          <div className="px-2 py-6 flex flex-col h-full sm:p-5">
            <h3 className="text-base sm:text-lg font-bold text-slate-800 leading-6">
              Langara College
            </h3>
            <p className="text-base mt-1 text-zinc-500 font-normal">
              See how greymatter helped Langara attract, recruit, admit and
              retain international students by catering to their unique needs.
            </p>
            <div className="mt-6">
              <a
                href="https://frequencyfoundry.com/case-study/langara-college-greymatter-implementation-project/"
                target="_blank"
                rel="noreferrer"
                className="pointer"
              >
                <button>
                  <p className="inline-flex items-center gap-x-2 text-sm font-normal text-[#456CCF]">
                    Read More
                    <svg
                      width="14"
                      height="15"
                      viewBox="0 0 14 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_884_22561)">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M7.5565 3.84033C7.5565 3.7243 7.51041 3.61302 7.42836 3.53097C7.34631 3.44893 7.23503 3.40283 7.119 3.40283H1.3125C0.964403 3.40283 0.630564 3.54111 0.384422 3.78725C0.138281 4.0334 0 4.36724 0 4.71533L0 13.4653C0 13.8134 0.138281 14.1473 0.384422 14.3934C0.630564 14.6396 0.964403 14.7778 1.3125 14.7778H10.0625C10.4106 14.7778 10.7444 14.6396 10.9906 14.3934C11.2367 14.1473 11.375 13.8134 11.375 13.4653V7.65883C11.375 7.5428 11.3289 7.43152 11.2469 7.34947C11.1648 7.26743 11.0535 7.22133 10.9375 7.22133C10.8215 7.22133 10.7102 7.26743 10.6281 7.34947C10.5461 7.43152 10.5 7.5428 10.5 7.65883V13.4653C10.5 13.5814 10.4539 13.6926 10.3719 13.7747C10.2898 13.8567 10.1785 13.9028 10.0625 13.9028H1.3125C1.19647 13.9028 1.08519 13.8567 1.00314 13.7747C0.921094 13.6926 0.875 13.5814 0.875 13.4653V4.71533C0.875 4.5993 0.921094 4.48802 1.00314 4.40597C1.08519 4.32393 1.19647 4.27783 1.3125 4.27783H7.119C7.23503 4.27783 7.34631 4.23174 7.42836 4.14969C7.51041 4.06764 7.5565 3.95636 7.5565 3.84033Z"
                          fill="#456CCF"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M14 1.21533C14 1.0993 13.9539 0.98802 13.8719 0.905973C13.7898 0.823926 13.6785 0.777832 13.5625 0.777832H9.1875C9.07147 0.777832 8.96019 0.823926 8.87814 0.905973C8.7961 0.98802 8.75 1.0993 8.75 1.21533C8.75 1.33136 8.7961 1.44264 8.87814 1.52469C8.96019 1.60674 9.07147 1.65283 9.1875 1.65283H12.5064L5.37775 8.78058C5.33708 8.82126 5.30481 8.86955 5.2828 8.9227C5.26078 8.97584 5.24945 9.03281 5.24945 9.09033C5.24945 9.14786 5.26078 9.20482 5.2828 9.25797C5.30481 9.31111 5.33708 9.35941 5.37775 9.40008C5.41843 9.44076 5.46672 9.47303 5.51987 9.49504C5.57301 9.51705 5.62998 9.52838 5.6875 9.52838C5.74503 9.52838 5.80199 9.51705 5.85514 9.49504C5.90829 9.47303 5.95658 9.44076 5.99725 9.40008L13.125 2.27146V5.59033C13.125 5.70636 13.1711 5.81764 13.2531 5.89969C13.3352 5.98174 13.4465 6.02783 13.5625 6.02783C13.6785 6.02783 13.7898 5.98174 13.8719 5.89969C13.9539 5.81764 14 5.70636 14 5.59033V1.21533Z"
                          fill="#456CCF"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_884_22561">
                          <rect
                            width="14"
                            height="14"
                            fill="white"
                            transform="translate(0 0.777832)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </p>
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CaseStudies;
