import React from "react";

const Modal = ({ id, children }) => {
  return (
    <div
      id={id}
      className="hs-overlay hidden w-full h-full fixed top-0 left-0 z-[60] overflow-x-hidden overflow-y-auto"
    >
      <div className="hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 mt-0 opacity-0 ease-out transition-all sm:w-full m-3 mx-auto min-h-[calc(100%-3.5rem)] flex items-center">
        <div className="flex flex-col bg-white border shadow-sm w-[90vw] lg:w-[45vw] rounded-md mx-auto">
          <div className="relative flex flex-row-reverse items-center justify-between">
            <button
              type="button"
              id="close-modal"
              className="top-0 right-0 flex items-center content-center w-8 h-8 text-sm text-gray-500 transition-all rounded-full hs-dropdown-toggle hover:text-gray-400 focus:outline-none focus:ring-2"
              data-hs-overlay={`#${id}`}
            >
              <span className="sr-only">Close</span>
              <svg
                className="w-3.5 h-3.5 mx-auto"
                width="8"
                height="8"
                viewBox="0 0 8 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.258206 1.00652C0.351976 0.912791 0.479126 0.860131 0.611706 0.860131C0.744296 0.860131 0.871447 0.912791 0.965207 1.00652L3.61171 3.65302L6.25822 1.00652C6.30432 0.958771 6.35952 0.920671 6.42052 0.894471C6.48152 0.868271 6.54712 0.854471 6.61352 0.853901C6.67992 0.853321 6.74572 0.865971 6.80722 0.891111C6.86862 0.916251 6.92442 0.953381 6.97142 1.00032C7.01832 1.04727 7.05552 1.1031 7.08062 1.16454C7.10572 1.22599 7.11842 1.29183 7.11782 1.35822C7.11722 1.42461 7.10342 1.49022 7.07722 1.55122C7.05102 1.61222 7.01292 1.6674 6.96522 1.71352L4.31871 4.36002L6.96522 7.00648C7.05632 7.10078 7.10672 7.22708 7.10552 7.35818C7.10442 7.48928 7.05182 7.61468 6.95912 7.70738C6.86642 7.80018 6.74102 7.85268 6.60992 7.85388C6.47882 7.85498 6.35252 7.80458 6.25822 7.71348L3.61171 5.06702L0.965207 7.71348C0.870907 7.80458 0.744606 7.85498 0.613506 7.85388C0.482406 7.85268 0.357007 7.80018 0.264297 7.70738C0.171597 7.61468 0.119017 7.48928 0.117877 7.35818C0.116737 7.22708 0.167126 7.10078 0.258206 7.00648L2.90471 4.36002L0.258206 1.71352C0.164476 1.61976 0.111816 1.4926 0.111816 1.36002C0.111816 1.22744 0.164476 1.10028 0.258206 1.00652Z"
                  fill="#1F2937"
                />
              </svg>
            </button>
          </div>

          {/* Video */}
          <div className="overflow-y-auto">{children}</div>
          <div className="flex items-center justify-end w-full px-4 py-2 border-t gap-x-4">
            <p className="text-sm font-normal md:text-base">
              Have questions, want pricing, or want to book a demo?
            </p>
            <button
              className="inline-flex items-center justify-center w-3/4 gap-4 px-2 py-2 text-sm font-semibold text-white transition-all bg-blue-500 border border-transparent rounded-md md:w-1/5 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 dark:focus:ring-offset-gray-800"
              data-hs-overlay="#contact-sales-modal"
            >
              Contact Sales
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
