import React, { useRef } from "react";
import PatchQuestionCta from "./PatchQuestionCta";
import ImageRecruit from "../assets/image_recruit.png";
import ImageAdmit from "../assets/image_admit.png";
import ImageRetain from "../assets/image_retain.png";
import ImagePromote from "../assets/image_promote.png";

const Stages = () => {
  const recruitRef = useRef(null);
  const admitRef = useRef(null);
  const retainRef = useRef(null);
  const promoteRef = useRef(null);

  function scrollToRef(ref) {
    if (!ref.current) return;
    window.scrollTo({
      top: ref.current.offsetTop,
      behavior: "smooth",
    });
  }

  return (
    <div className="max-w-full pt-6 lg:pt-14 mx-auto w-full mt-16">
      <h1 className="text-center font-lato text-xl w-8/12 md:text-2xl md:text-3xl lg:text-4xl text-slate-800 font-bold md:w-[50%] lg:w-[30%] mx-auto">
        With you at every stage of the student journey
      </h1>
      <p className="text-center font-lato text-base w-8/12 md:text-lg text-zinc-500 font-semibold md:w-full mx-auto mt-4">
        Explore how <span className="text-zinc-900">greymatter</span> helps
        support staff and administrators.
      </p>
      <div className="mt-16 bg-white w-full border-t border-b shadow-sm sticky -top-[1px]">
        <div className="flex flex-row gap-10 w-full justify-center text-slate-700 text-base font-normal">
          <button
            onClick={() => scrollToRef(recruitRef)}
            className="hover:text-[#C32027] border-b-4 md:px-5 border-b-transparent py-4 hover:border-b-red-600"
          >
            Recruit
          </button>
          <button
            onClick={() => scrollToRef(admitRef)}
            className="hover:text-[#C32027] border-b-4 md:px-5 border-b-transparent py-4 hover:border-b-red-600"
          >
            Admit
          </button>
          <button
            onClick={() => scrollToRef(retainRef)}
            className="hover:text-[#C32027] border-b-4 md:px-5 border-b-transparent py-4 hover:border-b-red-600"
          >
            Retain
          </button>
          <button
            onClick={() => scrollToRef(promoteRef)}
            className="hover:text-[#C32027] border-b-4 md:px-5 border-b-transparent py-4 hover:border-b-red-600"
          >
            Promote
          </button>
        </div>
      </div>

      {/* STAGES */}
      <div ref={recruitRef} />
      <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto lg:w-[79%]">
        {/* ------------------------------------- RECRUIT STAGE ------------------------------------------ */}
        <div className="max-w-[85rem] mx-auto px-4 sm:px-6 lg:px-8 mx-auto">
          <div className="flex flex-col md:flex-row lg:items-center mx-auto">
            <div className="flex flex-col md:w-7/12 mx-auto md:mb-20">
              <h2 className="text-xl font-lato text-left text-slate-800 font-medium md:text-2xl lg:text-4xl md:leading-tight md:mb-3">
                Recruit
              </h2>
              <p className="mt-2 text-base w-full lg:text-lg font-lato font-normal text-left text-zinc-500">
                Precise and personalized insights help recruiters<br></br>
                capture, engage and convert prospects.
              </p>
            </div>
            <div className="w-full mt-4 md:mt-0">
              <img
                className="w-full rounded-xl lg:ml-[6rem]"
                src={ImageRecruit}
                alt="A person using a laptop with the greymatter platform on the screen"
              />
            </div>
          </div>
        </div>
        <h2 className="font-lato text-base md:text-lg lg:text-xl font-bold text-[#C32027]">
          Features to support your teams
        </h2>
        <div className="grid grid-col md:grid-cols-2 lg:grid-cols-3 gap-8 w-full mt-6">
          {/* ------------------------------------- ROW ONE ------------------------------------------ */}

          {/* One */}
          <div className="border border-gray-200 font-lato rounded-md p-7">
            <p className="text-slate-900 font-semibold text-lg">
              Communication and outreach
            </p>
            <p className="text-zinc-500 text-base font-normal">
              Powerful multi-channel marketing automation converts data into
              automated, intelligent and personalized student journeys.
            </p>
          </div>

          {/* Two */}
          <div className="border border-gray-200 font-lato rounded-md p-7">
            <p className="text-slate-900 font-semibold text-lg">
              Event management
            </p>
            <p className="text-zinc-500 text-base font-normal">
              Whether your event is online or in-real-life, greymatter helps at
              every stage of your outreach, from invites to payment processing.
            </p>
          </div>

          {/* Three */}
          <div className="border border-gray-200 font-lato rounded-md p-7">
            <p className="text-slate-900 font-semibold text-lg">
              Prospective student portal
            </p>
            <p className="text-zinc-500 text-base font-normal">
              Create a great first impression by giving prospects all the
              information they need and an easy-to-use interface.
            </p>
          </div>
        </div>

        {/* SEE MORE CTA */}

        <p class="mt-8">
          <button
            class="hs-collapse-toggle inline-flex items-center gap-x-2 text-blue-600"
            id="hs-show-hide-collapse"
            data-hs-collapse="#recruit-see-more"
          >
            <span class="hs-collapse-open:hidden">See more features</span>
            <span class="hs-collapse-open:block hidden">See less features</span>
            <svg
              class="hs-collapse-open:rotate-180 w-2.5 h-2.5"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2 5L8.16086 10.6869C8.35239 10.8637 8.64761 10.8637 8.83914 10.6869L15 5"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
              />
            </svg>
          </button>
        </p>
        <div className="border-b-2 border-zinc-900 mt-8"></div>
        <div
          id="recruit-see-more"
          class="hs-collapse hidden w-full overflow-hidden transition-[height] duration-300"
          aria-labelledby="hs-show-hide-collapse"
        >
          <div className="-mt-4 sm:mt-0 w-full">
            {/* Four */}
            <div className="font-lato rounded-md p-7">
              <p className="text-slate-900 font-semibold text-lg">
                Social outreach and posting
              </p>
              <p className="text-zinc-500 text-base font-normal">
                Seamless integration with platforms like Facebook, X, LinkedIn
                and InsideView make it easy to get your message out.
              </p>
            </div>

            {/* Five */}
            <div className="font-lato rounded-md p-7">
              <p className="text-slate-900 font-semibold text-lg">
                Recruiting agency management
              </p>
              <p className="text-zinc-500 text-base font-normal">
                Customizable web portals for recruiting agents promote
                collaboration and efficiency.
              </p>
            </div>

            {/* Six */}
            <div className="font-lato rounded-md p-7">
              <p className="text-slate-900 font-semibold text-lg">
                Territory and travel management
              </p>
              <p className="text-zinc-500 text-base font-normal">
                Keep things running smoothly with management tools that track
                leads and ensure no duplication of effort.
              </p>
            </div>

            {/* Seven */}
            <div className="font-lato rounded-md p-7">
              <p className="text-slate-900 font-semibold text-lg">
                Prospect segmentation and management
              </p>
              <p className="text-zinc-500 text-base font-normal">
                Seeing students as individuals is the key to personalizing their
                journeys. Once you know who your students are, you’ll know how
                to talk to them.
              </p>
            </div>

            {/* Eight */}
            <div className="font-lato rounded-md p-7">
              <p className="text-slate-900 font-semibold text-lg">
                Inbound and outbound tele-recruiting
              </p>
              <p className="text-zinc-500 text-base font-normal">
                Sometimes all it take is a phone call. Streamline personal
                communications with tele-recruiting tools.
              </p>
            </div>

            {/* Nine */}
            <div className="font-lato rounded-md p-7">
              <p className="text-slate-900 font-semibold text-lg">
                Virtual agents and chatbots
              </p>
              <p className="text-zinc-500 text-base font-normal">
                Reduce frustrations by giving prospective students answers to
                their questions at any time of day.
              </p>
            </div>
          </div>
        </div>

        <div className="pb-14">
          <PatchQuestionCta content="Interested in learning how greymatter can help recruit students?" />
        </div>

        {/* ------------------------------------- ADMIT STAGE ------------------------------------------ */}
        <div ref={admitRef} />
        <div className="mt-14">
          <div className="max-w-[85rem] mx-auto px-4 sm:px-6 lg:px-8 mx-auto">
            <div className="flex flex-col-reverse md:flex-row lg:items-center mx-auto">
              <div className="w-full">
                <img
                  className="w-full mt-4 md:mt-0 rounded-xl lg:-ml-[4rem]"
                  src={ImageAdmit}
                  alt="A man and woman, the man is holding an ipad and showing the screen to the woman. The woman is holding a pen and notebook."
                />
              </div>
              <div className="flex flex-col md:w-7/12 mx-auto md:mb-20">
                <h2 className="text-xl font-lato text-left text-slate-800 font-medium md:text-2xl lg:text-4xl md:leading-tight md:mb-3">
                  Admit
                </h2>
                <p className="mt-2 text-base w-full lg:text-lg font-lato font-normal text-left text-zinc-500">
                  Simplify admissions with automated workflows and insightful
                  analytics.
                </p>
              </div>
            </div>
          </div>
          <h2 className="font-lato mt-6 text-base md:text-lg lg:text-xl font-bold text-[#C32027]">
            Features to boost efficiency
          </h2>
          <div className="grid grid-col md:grid-cols-2 lg:grid-cols-3 gap-8 w-full mt-6">
            {/* ------------------------------------- ROW ONE ------------------------------------------ */}

            {/*  One */}
            <div className="border border-gray-200 font-lato rounded-md p-7">
              <p className="text-slate-900 font-semibold text-lg">
                Application management
              </p>
              <p className="text-zinc-500 text-base font-normal">
                Accept and process online applications, track application
                completeness and manage application deadlines through the
                institution-branded greymatter applicant portal or via a mobile
                device.
              </p>
            </div>

            {/*  Two */}
            <div className="border border-gray-200 font-lato rounded-md p-7">
              <p className="text-slate-900 font-semibold text-lg">
                Applicant tracking
              </p>
              <p className="text-zinc-500 text-base font-normal">
                Manage and track the progress of every applicant. Effortlessly
                keep an eye on application status, document submission,
                evaluation and decision-making.
              </p>
            </div>

            {/*  Three */}
            <div className="border border-gray-200 font-lato rounded-md p-7">
              <p className="text-slate-900 font-semibold text-lg">
                Application review and evaluation
              </p>
              <p className="text-zinc-500 text-base font-normal">
                greymatter includes features for assigning applications to
                reviewers, tracking review progress and documenting evaluation
                feedback.
              </p>
            </div>
          </div>

          {/* SEE MORE CTA */}
          <p class="mt-8">
            <button
              class="hs-collapse-toggle inline-flex items-center gap-x-2 text-blue-600"
              id="hs-show-hide-collapse"
              data-hs-collapse="#admit-see-more"
            >
              <span class="hs-collapse-open:hidden">See more features</span>
              <span class="hs-collapse-open:block hidden">
                See less features
              </span>
              <svg
                class="hs-collapse-open:rotate-180 w-2.5 h-2.5"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2 5L8.16086 10.6869C8.35239 10.8637 8.64761 10.8637 8.83914 10.6869L15 5"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                />
              </svg>
            </button>
          </p>
          <div className="border-b-2 border-zinc-900 mt-8"></div>

          <div
            id="admit-see-more"
            class="hs-collapse hidden w-full overflow-hidden transition-[height] duration-300"
            aria-labelledby="hs-show-hide-collapse"
          >
            <div className="-mt-4 sm:mt-0 w-full">
              {/* Four */}
              <div className="font-lato rounded-md p-7">
                <p className="text-slate-900 font-semibold text-lg">
                  Enrolment management
                </p>
                <p className="text-zinc-500 text-base font-normal">
                  Manage the enrolment process, including acceptance tracking,
                  enrolment confirmation and deposit collection. greymatter also
                  offers features for managing waiting lists and deferrals.
                </p>
              </div>

              {/* Five */}
              <div className="font-lato rounded-md p-7">
                <p className="text-slate-900 font-semibold text-lg">
                  Document management
                </p>
                <p className="text-zinc-500 text-base font-normal">
                  Native integration to OneDrive and Sharepoint lets applicants
                  submit documents electronically and securely. You’ll also find
                  features for document verification, storage and retrieval that
                  are automatically linked to the applicant.
                </p>
              </div>

              {/* Six */}
              <div className="font-lato rounded-md p-7">
                <p className="text-slate-900 font-semibold text-lg">
                  Communication and engagement tools
                </p>
                <p className="text-zinc-500 text-base font-normal">
                  Tools for personalized communication through email, SMS and
                  other channels make all the difference. greymatter also offers
                  automated messaging, reminders and notifications to keep
                  applicants informed.
                </p>
              </div>

              {/* Seven */}
              <div className="font-lato rounded-md p-7">
                <p className="text-slate-900 font-semibold text-lg">
                  Workflow automation
                </p>
                <p className="text-zinc-500 text-base font-normal">
                  Streamline repetitive tasks and improve efficiency. Automate
                  processes such as application review, decision-making and
                  communication triggers.
                </p>
              </div>

              {/* Eight */}
              <div className="font-lato rounded-md p-7">
                <p className="text-slate-900 font-semibold text-lg">
                  Reporting and analytics
                </p>
                <p className="text-zinc-500 text-base font-normal">
                  Track and analyze applicant demographics, conversion rates,
                  funnel analysis and other key performance indicators. Then
                  make data-driven decisions and optimize your admissions
                  strategies.
                </p>
              </div>

              {/* Nine */}
              <div className="font-lato rounded-md p-7">
                <p className="text-slate-900 font-semibold text-lg">
                  Integration with other systems
                </p>
                <p className="text-zinc-500 text-base font-normal">
                  Integration with your student information system, financial
                  aid system, learning management system and others ensures
                  smooth data flow and eliminates the need for manual data entry
                  or duplicate data.
                </p>
              </div>

              {/* Ten */}
              <div className="font-lato rounded-md p-7">
                <p className="text-slate-900 font-semibold text-lg">
                  Security and compliance
                </p>
                <p className="text-zinc-500 text-base font-normal">
                  greymatter prioritizes data security and compliance with
                  relevant regulations such as GDPR or FERPA. It provides robust
                  data encryption, access controls and audit trails to protect
                  applicant data and ensure compliance with privacy laws.
                </p>
              </div>
            </div>
          </div>
          <div className="pb-14">
            <PatchQuestionCta content="Interested in learning how greymatter can help streamline your admissions process?" />
          </div>
        </div>

        {/* ------------------------------------- RETAIN STAGE ------------------------------------------ */}

        <div ref={retainRef} />
        <div className="mt-14">
          <div className="max-w-[85rem] mx-auto px-4 sm:px-6 lg:px-8 mx-auto">
            <div className="flex flex-col md:flex-row lg:items-center mx-auto">
              <div className="flex flex-col md:w-7/12 mx-auto md:mb-20">
                <h2 className="text-xl font-lato text-left text-slate-800 font-medium md:text-2xl lg:text-4xl md:leading-tight md:mb-3">
                  Retain
                </h2>
                <p className="mt-2 text-base w-full lg:text-lg font-lato font-normal text-left text-zinc-500">
                  Identifying and mitigating obstacles helps students excel
                  academically and personally.
                </p>
              </div>
              <div className="w-full mt-4 md:mt-0">
                <img
                  className="w-full rounded-xl lg:ml-[6rem]"
                  src={ImageRetain}
                  alt="A woman with headphones and a backpack, she is on an ipad. In the background is a screenshot of the greymatter platform"
                />
              </div>
            </div>
          </div>
          <h2 className="font-lato text-base md:text-lg lg:text-xl font-bold text-[#C32027]">
            Features to make workflow easy
          </h2>
          <div className="grid grid-col md:grid-cols-2 lg:grid-cols-3 gap-8 w-full mt-6">
            {/* ------------------------------------- ROW ONE ------------------------------------------ */}

            {/* One */}
            <div className="border border-gray-200 font-lato rounded-md p-7">
              <p className="text-slate-900 font-semibold text-lg">
                Student profile management
              </p>
              <p className="text-zinc-500 text-base font-normal">
                A centralized database stores and manages student information,
                including personal details, academic records, attendance and
                communication history.
              </p>
            </div>

            {/* Two */}
            <div className="border border-gray-200 font-lato rounded-md p-7">
              <p className="text-slate-900 font-semibold text-lg">
                Student success and degree plans
              </p>
              <p className="text-zinc-500 text-base font-normal">
                Create and manage individualized student success and degree
                plans, outlining specific goals, interventions, and progress
                tracking.
              </p>
            </div>

            {/* Three */}
            <div className="border border-gray-200 font-lato rounded-md p-7">
              <p className="text-slate-900 font-semibold text-lg">
                Early warnings and alerts
              </p>
              <p className="text-zinc-500 text-base font-normal">
                Identify at-risk students based on factors like attendance,
                grades or engagement. This allows proactive intervention to
                prevent dropouts.
              </p>
            </div>
          </div>

          {/* SEE MORE CTA */}
          <p class="mt-8">
            <button
              class="hs-collapse-toggle inline-flex items-center gap-x-2 text-blue-600"
              id="hs-show-hide-collapse"
              data-hs-collapse="#retain-see-more"
            >
              <span class="hs-collapse-open:hidden">See more features</span>
              <span class="hs-collapse-open:block hidden">
                See less features
              </span>
              <svg
                class="hs-collapse-open:rotate-180 w-2.5 h-2.5"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2 5L8.16086 10.6869C8.35239 10.8637 8.64761 10.8637 8.83914 10.6869L15 5"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                />
              </svg>
            </button>
          </p>
          <div className="border-b-2 border-zinc-900 mt-8"></div>

          <div
            id="retain-see-more"
            class="hs-collapse hidden w-full overflow-hidden transition-[height] duration-300"
            aria-labelledby="hs-show-hide-collapse"
          >
            <div className="-mt-4 sm:mt-0 w-full">
              {/* Four */}
              <div className="font-lato rounded-md p-7">
                <p className="text-slate-900 font-semibold text-lg">
                  Intervention management
                </p>
                <p className="text-zinc-500 text-base font-normal">
                  Help struggling students by tracking and managing
                  interventions like tutoring programs, counseling sessions and
                  academic support initiatives.
                </p>
              </div>

              {/* Five */}
              <div className="font-lato rounded-md p-7">
                <p className="text-slate-900 font-semibold text-lg">
                  Analytics and predictive modeling
                </p>
                <p className="text-zinc-500 text-base font-normal">
                  Add-ons like Foresight and Portrait allow for advanced
                  analytics and predictive modeling that can help identify
                  patterns, predict student behavior and provide insights to
                  inform retention strategies.
                </p>
              </div>

              {/* Six */}
              <div className="font-lato rounded-md p-7">
                <p className="text-slate-900 font-semibold text-lg">
                  Tracking and reporting
                </p>
                <p className="text-zinc-500 text-base font-normal">
                  Comprehensive tracking and reporting capabilities allow
                  administrators to monitor student progress, identify trends
                  and generate reports on retention rates and student
                  performance.
                </p>
              </div>

              {/* Seven */}
              <div className="font-lato rounded-md p-7">
                <p className="text-slate-900 font-semibold text-lg">
                  Workflow automation
                </p>
                <p className="text-zinc-500 text-base font-normal">
                  Automating repetitive administrative tasks, such as sending
                  reminders or scheduling appointments, saves time and lets
                  staff members focus on personalized student support.
                </p>
              </div>

              {/* Eight */}
              <div className="font-lato rounded-md p-7">
                <p className="text-slate-900 font-semibold text-lg">
                  Integration with Learning Management Systems (LMS)
                </p>
                <p className="text-zinc-500 text-base font-normal">
                  Integration with your LMS allows for seamless data exchange
                  between platforms and provides a holistic view of student
                  performance and engagement.
                </p>
              </div>

              {/* Nine */}
              <div className="font-lato rounded-md p-7">
                <p className="text-slate-900 font-semibold text-lg">
                  Communication tools
                </p>
                <p className="text-zinc-500 text-base font-normal">
                  Integration with your student information system, financial
                  aid system, learning management system and others ensures
                  smooth data flow and eliminates the need for manual data entry
                  or duplicate data.
                </p>
              </div>

              {/* Ten */}
              <div className="font-lato rounded-md p-7">
                <p className="text-slate-900 font-semibold text-lg">
                  Advising caseload management
                </p>
                <p className="text-zinc-500 text-base font-normal">
                  Help advising teams work more efficiently with comprehensive
                  case management functionality. Features include appointment
                  scheduling, task management for follow-ups and reminders, and
                  reporting and analytics tools.
                </p>
              </div>
            </div>
          </div>
          <div className="pb-14">
            <PatchQuestionCta content="Interested in learning how greymatter can increase your students' success rate?" />
          </div>
        </div>

        {/* ------------------------------------- PROMOTE STAGE ------------------------------------------ */}

        <div ref={promoteRef} />
        <div className="mt-14">
          <div className="max-w-[85rem] mx-auto px-4 sm:px-6 lg:px-8 mx-auto">
            <div className="flex flex-col-reverse md:flex-row lg:items-center mx-auto mb-4">
              <div className="w-full">
                <img
                  className="w-11/12 rounded-xl mt-4 md:mt-0 lg:-ml-[3rem]"
                  src={ImagePromote}
                  alt="A cellphone with the greymatter logo on the screen, students on computers in the background"
                />
              </div>
              <div className="flex flex-col md:w-7/12 mx-auto md:mb-20">
                <h2 className="text-xl font-lato text-left text-slate-800 font-medium md:text-2xl lg:text-4xl md:leading-tight md:mb-3">
                  Promote
                </h2>
                <p className="mt-2 text-base w-full lg:text-lg font-lato font-normal text-left text-zinc-500">
                  Spread the word about programs and services for adult
                  learners, professional development and workforce training.
                </p>
              </div>
            </div>
          </div>
          <h2 className="font-lato text-base md:text-lg lg:text-xl font-bold text-[#C32027]">
            Features to simplify communication
          </h2>
          <div className="grid grid-col md:grid-cols-2 lg:grid-cols-3 gap-8 w-full mt-6">
            {/* ------------------------------------- ROW ONE ------------------------------------------ */}

            {/*  One */}
            <div className="border border-gray-200 font-lato rounded-md p-7">
              <p className="text-slate-900 font-semibold text-lg">
                Program and course management
              </p>
              <p className="text-zinc-500 text-base font-normal">
                Manage and track programs, courses, and workshops with tools for
                scheduling, registration and enrolment management.
              </p>
            </div>

            {/*  Two */}
            <div className="border border-gray-200 font-lato rounded-md p-7">
              <p className="text-slate-900 font-semibold text-lg">
                Student registration and enrolment
              </p>
              <p className="text-zinc-500 text-base font-normal">
                greymatter facilitates the online registration and enrolment
                process, allowing individuals to sign up for courses, select
                payment options, and manage course schedules.
              </p>
            </div>

            {/*  Three */}
            <div className="border border-gray-200 font-lato rounded-md p-7">
              <p className="text-slate-900 font-semibold text-lg">
                Certification and credential management
              </p>
              <p className="text-zinc-500 text-base font-normal">
                Track completion of courses, assessments and professional
                development requirements, and issue digital certificates or
                badges.
              </p>
            </div>
          </div>

          {/* SEE MORE CTA */}
          <p class="mt-8">
            <button
              class="hs-collapse-toggle inline-flex items-center gap-x-2 text-blue-600"
              id="hs-show-hide-collapse"
              data-hs-collapse="#promote-see-more"
            >
              <span class="hs-collapse-open:hidden">See more features</span>
              <span class="hs-collapse-open:block hidden">
                See less features
              </span>
              <svg
                class="hs-collapse-open:rotate-180 w-2.5 h-2.5"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2 5L8.16086 10.6869C8.35239 10.8637 8.64761 10.8637 8.83914 10.6869L15 5"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                />
              </svg>
            </button>
          </p>

          <div className="border-b-2 border-zinc-900 mt-8"></div>

          <div
            id="promote-see-more"
            class="hs-collapse hidden w-full overflow-hidden transition-[height] duration-300"
            aria-labelledby="hs-show-hide-collapse"
          >
            {/* Four */}
            <div className="font-lato rounded-md p-7">
              <p className="text-slate-900 font-semibold text-lg">
                Continuing Education Unit (CEU) tracking
              </p>
              <p className="text-zinc-500 text-base font-normal">
                greymatter includes features to track and manage (CEUs) earned
                by participants in continuing education and professional
                development programs.
              </p>
            </div>

            {/* Five */}
            <div className="font-lato rounded-md p-7">
              <p className="text-slate-900 font-semibold text-lg">
                Payment processing
              </p>
              <p className="text-zinc-500 text-base font-normal">
                Integration with payment gateways and the ability to process
                payments within greymatter allow for secure and convenient
                online payments for course fees and materials.
              </p>
            </div>

            {/* Six */}
            <div className="font-lato rounded-md p-7">
              <p className="text-slate-900 font-semibold text-lg">
                Marketing and communication
              </p>
              <p className="text-zinc-500 text-base font-normal">
                Promote courses, events and programs with targeted marketing
                campaigns that use email marketing, personalized messaging and
                automated communications.
              </p>
            </div>

            {/* Seven */}
            <div className="font-lato rounded-md p-7">
              <p className="text-slate-900 font-semibold text-lg">
                Customer and contact management
              </p>
              <p className="text-zinc-500 text-base font-normal">
                A centralized database manages student and participant
                information, including contact details, demographics,
                communication history and course enrolment history.
              </p>
            </div>

            {/* Eight */}
            <div className="font-lato rounded-md p-7">
              <p className="text-slate-900 font-semibold text-lg">
                Learning Management System (LMS) integration
              </p>
              <p className="text-zinc-500 text-base font-normal">
                Integration with your LMS allows for a comprehensive learning
                experience by giving you acess to course materials, assignments,
                assessments and grading.
              </p>
            </div>

            {/* Nine */}
            <div className="font-lato rounded-md p-7">
              <p className="text-slate-900 font-semibold text-lg">
                Reporting and analytics
              </p>
              <p className="text-zinc-500 text-base font-normal">
                Robust reporting capabilities inform decision-making and
                strategy by providing insights into program performance,
                enrolment trends, revenue generation and participant
                satisfaction.
              </p>
            </div>

            {/* Ten */}
            <div className="font-lato rounded-md p-7">
              <p className="text-slate-900 font-semibold text-lg">
                Customization and integration
              </p>
              <p className="text-zinc-500 text-base font-normal">
                greymatter extends the Dynamics 365 platform’s customization
                capabilities to accommodate specific program requirements. We
                also offer integration capabilities with other systems such as
                accounting software, marketing automation tools and online
                learning platforms.
              </p>
            </div>
          </div>
          <div className="pb-8">
            <PatchQuestionCta content="Interested in learning how greymatter can support your professional development offerings?" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Stages;
