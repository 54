import "../input.css";
import HeroBanner from "../components/HeroBanner";
import CardsSection from "../components/CardsSection";
import HowItWorks from "../components/HowItWorks";
import Tabs from "../components/Tabs";
import TextBlockCallout from "../components/TextBlockCallout";
import CaseStudies from "../components/CaseStudies";
import Faq from "../components/Faq";
import ResourcesAndSupport from "../components/ResourcesAndSupport";
import ImageAccordion from "../components/ImageAccordion";

function Home() {
  return (
    <div className="home">
      <HeroBanner />
      <CardsSection />
      <HowItWorks />
      <Tabs />
      <ResourcesAndSupport />
      <ImageAccordion />
      <TextBlockCallout />
      <CaseStudies />
      <Faq />
    </div>
  );
}

export default Home;
