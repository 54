import React from "react";

const PatchQuestionCta = ({ content }) => {
  return (
    <div className="flex flex-col justify-center mt-16">
      <p className="text-center text-slate-900 flex flex-col justify-center font-bold text-base lg:text-lg mx-auto">
        {content}
        <button
          className="md:ml-2 text-base lg:text-lg flex flex-row items-center justify-center gap-2 hover:pointer hover:underline text-blue-600 mt-4 md:mt-2"
          data-hs-overlay="#contact-sales-modal"
        >
          Contact Sales
          <svg
            className="w-3.5 h-3"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0.975821 6.92249C0.43689 6.92249 -3.50468e-07 7.34222 -3.27835e-07 7.85999C-3.05203e-07 8.37775 0.43689 8.79749 0.975821 8.79749L12.7694 8.79748L7.60447 13.7596C7.22339 14.1257 7.22339 14.7193 7.60447 15.0854C7.98555 15.4515 8.60341 15.4515 8.98449 15.0854L15.6427 8.68862C16.1191 8.23098 16.1191 7.48899 15.6427 7.03134L8.98449 0.634573C8.60341 0.268455 7.98555 0.268456 7.60447 0.634573C7.22339 1.00069 7.22339 1.59428 7.60447 1.9604L12.7694 6.92248L0.975821 6.92249Z"
              fill="currentColor"
            />
          </svg>
        </button>
      </p>
    </div>
  );
};

export default PatchQuestionCta;
