import React from "react";

const ResourcesAndSupport = () => {
  return (
    <div className="max-w-[85rem] mt-28 px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
      <div className="mx-auto text-center mb-10 lg:mb-14">
        <div className="mb-6 flex flex-row items-center">
          <hr className="w-11/12 md:w-full border-b border-[#C32027] font-light" />
          <p className="w-full md:w-[75%] lg:w-7/12 text-[#C32027] font-medium bg-white">
            Why choose or switch to greymatter
          </p>
          <hr className="w-11/12 md:w-full border-b border-[#C32027] font-light" />
        </div>

        <div className="md:w-3/4 lg:w-[60%] mx-auto">
          <h2 className="text-2xl text-slate-800 font-bold md:text-4xl md:leading-tight">
            Tool, features and capabilities that help students and
            higher-education institutions succeed 
          </h2>
        </div>
      </div>

      <div className="grid grid-col md:grid-cols-2 lg:grid-cols-3 gap-6 h-full">
        {/* Card One */}
        <div className="h-[12rem] border border-[#E5E7EB] hover:border-transparent hover:shadow-lg transition-all duration-300">
          <div className="mt-8">
            <div className="text-center md:text-left px-5 mr-auto md:mx-auto">
              <h3 className="text-lg leading-[21px] font-semi text-slate-800">
                Customizable dashboards with embedded visualizations
              </h3>
            </div>
          </div>
          <div className="px-5 mx-auto">
            <p className="text-slate-600 font-normal mt-[0.5rem]">
              Get the up-to-date information staff members need to do their jobs
              effectively. Dashboards can be defined for the institution, a
              department or individual users.
            </p>
          </div>
        </div>

        {/* Card Two */}
        <div className="h-[12rem] border border-[#E5E7EB] hover:border-transparent hover:shadow-lg transition-all duration-300">
          <div className="mt-8">
            <div className="text-center md:text-left px-5 mx-auto">
              <h3 className="text-lg leading-[21px] font-semi text-slate-800">
                Social media tools and integration
              </h3>
            </div>
          </div>
          <div className="px-5 mx-auto">
            <p className="text-slate-600 font-normal mt-[0.5rem]">
              Seamless integration with leading platforms means you can post and
              schedule content as needed. Social monitoring and sentiment
              analysis lets you see how well you’re connecting.
            </p>
          </div>
        </div>

        {/* Card Three */}
        <div className="h-[12rem] border border-[#E5E7EB] hover:border-transparent hover:shadow-lg transition-all duration-300">
          <div className="mt-8">
            <div className="text-center md:text-left px-5 mx-auto">
              <h3 className="text-lg leading-[21px] font-semi text-slate-800">
                Automate workflows and business processes
              </h3>
            </div>
          </div>
          <div className="px-5 mx-auto">
            <p className="text-slate-600 font-normal mt-[0.5rem]">
              Native integration with Power Automate allows the creation of
              automated approval workflows and actions across the most common
              applications and services.
            </p>
          </div>
        </div>

        {/* Card Four */}
        <div className="h-[12rem] border border-[#E5E7EB] hover:border-transparent hover:shadow-lg transition-all duration-300">
          <div className="mt-8">
            <div className="text-center md:text-left px-5 mr-auto md:mx-auto">
              <h3 className="text-lg leading-[21px] font-semi text-slate-800">
                Application and admission management
              </h3>
            </div>
          </div>
          <div className="px-5 mx-auto">
            <p className="text-slate-600 font-normal mt-[0.5rem]">
              Comprehensive functionality helps you manage domestic and
              international recruiting, manage recruiting agencies and handle
              online applications.
            </p>
          </div>
        </div>

        {/* Card Five */}
        <div className="h-[12rem] border border-[#E5E7EB] hover:border-transparent hover:shadow-lg transition-all duration-300">
          <div className="mt-8">
            <div className="text-center md:text-left px-5 mx-auto">
              <h3 className="text-lg leading-[21px] font-semi text-slate-800">
                Virtual agent chatbots
              </h3>
            </div>
          </div>
          <div className="px-5 mx-auto">
            <p className="text-slate-600 font-normal mt-[0.5rem]">
              Online chat and chatbot can be added on the greymatter portal.
              Once trained on a list of FAQs, the chatbot uses deep-language
              learning to field a wider range of questions.
            </p>
          </div>
        </div>

        {/* Card Six */}
        <div className="h-[12rem] border border-[#E5E7EB] hover:border-transparent hover:shadow-lg transition-all duration-300">
          <div className="mt-8">
            <div className="text-center md:text-left px-5 mx-auto">
              <h3 className="text-lg leading-[21px] font-semi text-slate-800">
                Event management and marketing automation
              </h3>
            </div>
          </div>
          <div className="px-5 mx-auto">
            <p className="text-slate-600 mt-[0.5rem] font-normal">
              Organize and manage events for students, alumni and partners.
              Filtering and query tools make it easy to create marketing lists
              and execute tailored campaigns.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResourcesAndSupport;
