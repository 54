import React from "react";
import LogoFooter from "../assets/logo-footer.png";

const Footer = () => {
  return (
    <div>
      <footer className="mt-auto w-full max-w-[85rem] py-10 px-4 sm:px-6 lg:px-8 mx-auto">
        <div className="text-center">
          <div>
            <span
              className="flex-none text-xl font-semibold text-black dark:text-white"
              href="#"
              aria-label="Brand"
            >
              <img
                src={LogoFooter}
                alt="greymatter Logo"
                className="w-2/5 md:w-1/5 lg:w-[10%] mx-auto"
              />
            </span>
          </div>

          <div className="mt-3">
            <p className="text-gray-500">
              Copyright © 2023 greymatter. All rights reserved. A product of{" "}
              <a
                href="https://frequencyfoundry.com/"
                target="_blank"
                rel="noreferrer"
                className="text-[#475569] font-bold underline underline-offset-1"
              >
                Frequency Foundry
              </a>
              .
            </p>
          </div>
          <div className="mt-0">
            <p className="text-gray-500 hover:underline pointer">
              <a
                href="https://frequencyfoundry.com/privacy-policy/"
                target="_blank"
                rel="noreferrer"
              >
                Privacy Policy
              </a>
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
