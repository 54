import React, { useState } from "react";
import ReactPlayer from "react-player";

const VideoPlayer = ({ url }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  window.addEventListener("close.hs.overlay", ($overlayEl) => {
    setIsPlaying(false);
  });

  return (
    <ReactPlayer
      url={url}
      width="100%"
      controls={true}
      playing={isPlaying}
      onPlay={() => {
        setIsPlaying(true);
      }}
    />
  );
};

export default VideoPlayer;
