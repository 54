import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import ToolsAndFeatures from "./pages/ToolsAndFeatures";
import Navigation from "./components/Navigation";
import ModalSales from "./components/ModalSales";
import Footer from "./components/Footer";

function App() {
  return (
    <>
      <Navigation />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/tools-and-features" element={<ToolsAndFeatures />} />
      </Routes>
      <ModalSales />
      <Footer />
    </>
  );
}

export default App;
