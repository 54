import React from "react";

const AnnouncementBar = () => {
  return (
    <div>
      <div
        id="ab-full-width-with-dismiss-button-on-blue-bg"
        className="hs-removing:-translate-y-full bg-[#C32027] "
      >
        <div className="max-w-full px-4 py-4 mx-0 sm:px-6">
          <div className="flex">
            <p className="m-auto text-white">
              Find us at the EDUCAUSE Annual Conference Oct 9 - 12.
              <a
                href="https://events.educause.edu/annual-conference"
                target="_blank"
                rel="noreferrer"
                className="ml-2 decoration-2 underline hover:text-white/[.8]"
              >
                Learn more
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnnouncementBar;
