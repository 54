import React from "react";

const HowItWorks = () => {
  return (
    <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
      <div className="mx-auto text-center mb-10 lg:mb-14">
        <div className="mb-6 flex flex-row items-center">
          <hr className="w-11/12 md:w-full border-b border-[#C32027] font-light" />
          <p className="w-full md:w-[40%] lg:w-[25%] text-[#C32027] font-medium bg-white">
            How It Works
          </p>
          <hr className="w-11/12 md:w-full border-b border-[#C32027] font-light" />
        </div>

        <div className="md:w-3/4 lg:w-[60%] mx-auto">
          <h2 className="text-2xl text-slate-800 font-bold md:text-4xl md:leading-tight">
            Empower students. Empower on-campus teams.
          </h2>
        </div>
      </div>

      <div className="grid grid-col md:grid-cols-2 lg:grid-cols-4 gap-6 h-full">
        {/* Card One */}
        <div className="group grid grid-col h-[19rem] lg:h-[26rem] md:justify-items-start border border-[#E5E7EB]">
          <div className="grid grid-col mt-8">
            <div className="text-center md:text-left px-5 mx-auto">
              <h3 className="text-lg leading-[21px] font-semi text-slate-800">
                Institution Operations
              </h3>
            </div>
          </div>
          <div className="px-5 mx-auto">
            <ul className="list-disc list-outside pl-4">
              <p className="text-slate-600 font-normal -mt-20 md:-mt-28 lg:-mt-32 xl:-mt-44">
                <li>Automate business processes</li>
                <li>Best-in-class technology platform </li>
                <li>Integrate with SIS, LMS,and other key legacy systems</li>
                <li>Leverage Microsoft’s world-class security</li>
              </p>
            </ul>
          </div>
        </div>

        {/* Card Two */}
        <div className="group grid grid-col h-[19rem] lg:h-[26rem] md:justify-items-start border border-[#E5E7EB]">
          <div className="grid grid-col mt-8">
            <div className="text-center md:text-left px-5 mx-auto">
              <h3 className="text-lg leading-[21px] font-semi text-slate-800">
                Recruitment and Admissions
              </h3>
            </div>
          </div>
          <div className="px-5 mt-8 mx-auto">
            <ul className="list-disc pl-0 list-outside pl-4">
              <p className="text-slate-600 font-normal -mt-20 md:-mt-28 lg:-mt-[4rem] min-[1060px]:-mt-[6rem] xl:-mt-40">
                <li>Recruit domestic and international prospects</li>
                <li>
                  Create automated campaigns to give prospects relevant
                  information 
                </li>
                <li>
                  Convert with application and admission management for
                  traditional or workforce education programs
                </li>
              </p>
            </ul>
          </div>
        </div>

        {/* Card Three */}
        <div className="group grid grid-col h-[19rem] lg:h-[26rem] md:justify-items-start border border-[#E5E7EB]">
          <div className="grid grid-col mt-8">
            <div className="text-center md:text-left px-5 mx-auto">
              <h3 className="text-lg leading-[21px] font-semi text-slate-800">
                Student Experience
              </h3>
            </div>
          </div>
          <div className="px-5 mx-auto">
            <ul className="list-disc list-outside pl-4">
              <p className="text-slate-600 font-normal -mt-20 lg:-mt-24 xl:-mt-44">
                <li>
                  Empower students with AI features for advising, risk
                  management and degree planning
                </li>
                <li>Native integration with SIS and LMS</li>
                <li>
                  Track student cases to ensure they are resolved smoothly
                </li>
              </p>
            </ul>
          </div>
        </div>

        {/* Card Four */}
        <div className="group grid grid-col h-[19rem] lg:h-[26rem] md:justify-items-start border border-[#E5E7EB]">
          <div className="grid grid-col mt-8">
            <div className="text-center md:text-left px-5 mx-auto">
              <h3 className="text-lg leading-[21px] font-semi text-slate-800">
                Alumni and Partner Engagement
              </h3>
            </div>
          </div>
          <div className="px-5 mt-6 mx-auto">
            <ul className="list-disc list-outside pl-4">
              <p className="text-slate-600 font-normal -mt-24 lg:-mt-12 xl:-mt-[8.75rem] min-[1324px]:-mt-40 min-[1348px]:-mt-[11.75rem]">
                <li>
                  Advance your institution’s donor engagement and fundraising
                  goals
                </li>
                <li>
                  Engage your alumni in continuing and workforce education
                  offerings
                </li>
                <li>
                  Promote your program offerings through a seamless e-commerce
                  experience
                </li>
              </p>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
