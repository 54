import React from "react";
import Foresight from "../assets/foresight.svg";
import Portrait from "../assets/portrait.svg";
import Stream from "../assets/stream.svg";

const NewAndNoteworthy = () => {
  return (
    <div className="max-w-[85rem] -mt-4 min-[575px]:mt-14 min-[830px]:mt-16 md:mt-2 px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto 2xl:mt-40">
      <div className="flex flex-col w-full mx-auto mb-16 items-center">
        <h2 className="text-2xl text-center md:text-left text-slate-800 font-medium md:text-3xl md:leading-tight mb-3">
          What's new
        </h2>
        <p className="mt-6 text-base w-11/12 lg:mt-4 lg:text-md lg:w-3/5 font-normal text-center text-zinc-500">
          <span className="text-zinc-900">greymatter</span> is built from the
          ground up to serve higher-education.
          <br></br>
          Our latest features take it to a whole new level.
        </p>
      </div>
      <div className="flex flex-col w-full items-center content-start gap-6 mx-auto lg:flex-row md:w-4/5">
        {/* Card One */}
        <div className="flex flex-col p-4 group rounded-xl md:p-7 w-full md:w-7/12">
          <div className="flex items-center justify-center w-full rounded-full">
            <img
              className="w-5/12 lg:w-7/12"
              src={Foresight}
              alt="Red icon of a desktop computer screen and a megaphone. There are little squares coming out of the megaphone"
            />
          </div>
          <div className="mt-5">
            <h3 className="text-lg font-medium font-semibold text-slate-800 text-center">
              Foresight
            </h3>
            <p className="mt-1 text-base font-normal text-center text-zinc-500">
              Retain and advance students with early alerts and risk management.
              Foresight is enhanced with generative AI and predictive analytics
              to provide a holistic view of every student and identify when
              intervention is needed.
            </p>
          </div>
        </div>

        {/* Card Two */}
        <div className="flex flex-col p-4 group rounded-xl md:p-7 w-full md:w-7/12">
          <div className="flex items-center justify-center w-full rounded-full">
            <img
              className="w-5/12 lg:w-7/12"
              src={Portrait}
              alt="Red icon of a person inside of a circle, there is a circuit board at the top left of the circle"
            />
          </div>
          <div className="mt-5">
            <h3 className="text-lg font-medium font-semibold text-slate-800 text-center">
              Portrait
            </h3>
            <p className="mt-1 text-base font-normal text-center text-zinc-500">
              Our generative AI learning support assistant is built on the
              understanding that every student journey is unique. Portrait
              engages students based on their individual needs, challenges and
              learning patterns.
            </p>
          </div>
        </div>

        {/* Card Three */}
        <div className="flex flex-col p-4 group rounded-xl md:p-7  w-full md:w-7/12">
          <div className="flex items-center justify-center w-full rounded-full">
            <img
              className="w-5/12 lg:-mt-6 lg:w-7/12"
              src={Stream}
              alt="Red icon of a series of circles attached to a larger circle in the middle by lines. The larger circle has a check mark inside of it."
            />
          </div>
          <div className="mt-5">
            <h3 className="text-lg font-medium font-semibold text-slate-800 text-center">
              Stream
            </h3>
            <p className="mt-1 text-base font-normal text-center text-zinc-500">
              Omnichannel queue management optimizes resource allocation. It
              gets students to the right staff faster while providing better
              forecasting, greater flexibility and deeper insights.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewAndNoteworthy;
