import React from "react";

const Faq = () => {
  return (
    <div className="max-w-[85rem] mt-20 md:mt-44 mb-16 px-4 sm:px-6 lg:px-8 mx-auto">
      <div className="flex flex-row items-center mx-auto mt-16 mb-6 text-center">
        <hr className="w-11/12 md:w-full border-b border-[#C32027] font-light" />
        <p className="w-[40%] lg:w-[10%] text-[#C32027] font-medium bg-white">
          FAQs
        </p>
        <hr className="w-11/12 md:w-full border-b border-[#C32027] font-light" />
      </div>

      {/* FAQ Accordions */}

      <div className="px-4 mx-auto sm:px-6 lg:px-8 lg:py-2">
        <div className="mx-auto mb-10 text-center lg:mb-14">
          <h2 className="w-11/12 mx-auto text-2xl font-bold text-center sm:text-4xl text-slate-800">
            Your questions, answered
          </h2>
        </div>

        {/* Accordion starts here */}

        <div className="max-w-2xl mx-auto">
          <div className="hs-accordion-group">
            {/* Accordion One */}
            <div
              className="p-6 hs-accordion hs-accordion-active:bg-gray-100 rounded-xl active"
              id="hs-basic-with-title-and-arrow-stretched-heading-one"
            >
              <button
                className="inline-flex items-center justify-between w-full pb-3 font-semibold text-left transition hs-accordion-toggle group gap-x-3 md:text-lg text-slate-800 hover:text-gray-500"
                aria-controls="hs-basic-with-title-and-arrow-stretched-collapse-one"
              >
                Does greymatter enable self-service?
                <svg
                  className="block w-3 h-3 text-gray-600 hs-accordion-active:hidden group-hover:text-gray-500"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2 5L8.16086 10.6869C8.35239 10.8637 8.64761 10.8637 8.83914 10.6869L15 5"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
                <svg
                  className="hidden w-3 h-3 text-gray-600 hs-accordion-active:block group-hover:text-gray-500"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2 11L8.16086 5.31305C8.35239 5.13625 8.64761 5.13625 8.83914 5.31305L15 11"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
              </button>
              <div
                id="hs-basic-with-title-and-arrow-stretched-collapse-one"
                className="hs-accordion-content w-full overflow-hidden transition-[height] duration-300"
                aria-labelledby="hs-basic-with-title-and-arrow-stretched-heading-one"
              >
                <p className="text-base font-normal text-zinc-500">
                  greymatter has a self-serve portal that integrates with
                  existing web properties to ensure consistency of branding and
                  usability. The self-serve portal can be exposed to prospects,
                  applicants, students, alumni, other institutional partners or
                  the public. It’s your choice.
                </p>
              </div>
            </div>

            {/* Accordion Two */}
            <div
              className="p-6 hs-accordion hs-accordion-active:bg-gray-50 rounded-xl"
              id="hs-basic-with-title-and-arrow-stretched-heading-two"
            >
              <button
                className="inline-flex items-center justify-between w-full pb-3 font-semibold text-left transition hs-accordion-toggle group gap-x-3 md:text-lg text-slate-800 hover:text-gray-500"
                aria-controls="hs-basic-with-title-and-arrow-stretched-collapse-two"
              >
                Will greymatter integrate with my institution systems?
                <svg
                  className="block w-3 h-3 text-gray-600 hs-accordion-active:hidden group-hover:text-gray-500"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2 5L8.16086 10.6869C8.35239 10.8637 8.64761 10.8637 8.83914 10.6869L15 5"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
                <svg
                  className="hidden w-3 h-3 text-gray-600 hs-accordion-active:block group-hover:text-gray-500"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2 11L8.16086 5.31305C8.35239 5.13625 8.64761 5.13625 8.83914 5.31305L15 11"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
              </button>
              <div
                id="hs-basic-with-title-and-arrow-stretched-collapse-two"
                className="hs-accordion-content hidden w-full overflow-hidden transition-[height] duration-300"
                aria-labelledby="hs-basic-with-title-and-arrow-stretched-heading-two"
              >
                <p className="text-base font-normal text-zinc-500">
                  greymatter operates with your SIS and other key systems.
                  greymatter is also completely integrated with Office 365
                  (Outlook/Exchange, Excel, Word, etc.) and allows the use of
                  O365-based templates (Word and Excel) to reduce manual
                  processes.
                </p>
              </div>
            </div>

            {/* Accordion Three */}

            <div
              className="p-6 hs-accordion hs-accordion-active:bg-gray-50 rounded-xl"
              id="hs-basic-with-title-and-arrow-stretched-heading-three"
            >
              <button
                className="inline-flex items-center justify-between w-full pb-3 font-semibold text-left transition hs-accordion-toggle group gap-x-3 md:text-lg text-slate-800 hover:text-gray-500"
                aria-controls="hs-basic-with-title-and-arrow-stretched-collapse-three"
              >
                What reporting tools does greymatter offer?
                <svg
                  className="block w-3 h-3 text-gray-600 hs-accordion-active:hidden group-hover:text-gray-500"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2 5L8.16086 10.6869C8.35239 10.8637 8.64761 10.8637 8.83914 10.6869L15 5"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
                <svg
                  className="hidden w-3 h-3 text-gray-600 hs-accordion-active:block group-hover:text-gray-500"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2 11L8.16086 5.31305C8.35239 5.13625 8.64761 5.13625 8.83914 5.31305L15 11"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
              </button>
              <div
                id="hs-basic-with-title-and-arrow-stretched-collapse-three"
                className="hs-accordion-content hidden w-full overflow-hidden transition-[height] duration-300"
                aria-labelledby="hs-basic-with-title-and-arrow-stretched-heading-three"
              >
                <p className="text-base font-normal text-zinc-500">
                  greymatter includes comprehensive reporting that can be
                  supplemented with user-built ad-hoc queries using the advanced
                  filtering in the Dynamics 365 platform. Reports and dashboards
                  can be defined for the institution, departments or individual
                  users. Dashboards provide graphical snapshots of progress
                  against measured goals, KPIs, task lists, etc. greymatter also
                  supports real-language reporting and analysis using Microsoft
                  Power BI.
                </p>
              </div>
            </div>

            {/* Accordion Four */}

            <div
              className="p-6 hs-accordion hs-accordion-active:bg-gray-50 rounded-xl"
              id="hs-basic-with-title-and-arrow-stretched-heading-four"
            >
              <button
                className="inline-flex items-center justify-between w-full pb-3 font-semibold text-left transition hs-accordion-toggle group gap-x-3 md:text-lg text-slate-800 hover:text-gray-500"
                aria-controls="hs-basic-with-title-and-arrow-stretched-collapse-four"
              >
                Does greymatter provide AI functionality?
                <svg
                  className="block w-3 h-3 text-gray-600 hs-accordion-active:hidden group-hover:text-gray-500"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2 5L8.16086 10.6869C8.35239 10.8637 8.64761 10.8637 8.83914 10.6869L15 5"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
                <svg
                  className="hidden w-3 h-3 text-gray-600 hs-accordion-active:block group-hover:text-gray-500"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2 11L8.16086 5.31305C8.35239 5.13625 8.64761 5.13625 8.83914 5.31305L15 11"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
              </button>
              <div
                id="hs-basic-with-title-and-arrow-stretched-collapse-four"
                className="hs-accordion-content hidden w-full overflow-hidden transition-[height] duration-300"
                aria-labelledby="hs-basic-with-title-and-arrow-stretched-heading-four"
              >
                <p className="text-base font-normal text-zinc-500">
                  Built on the Microsoft Azure & Power Platforms, greymatter
                  harnesses the machine learning and AI capabilities of Azure
                  Cognitive services and Dynamics 365. It provides predictive
                  intelligence and prescriptive insights as well as the
                  automation capabilities to act on that intelligence.
                </p>
              </div>
            </div>

            {/* Accordion Five */}

            <div
              className="p-6 hs-accordion hs-accordion-active:bg-gray-50 rounded-xl"
              id="hs-basic-with-title-and-arrow-stretched-heading-five"
            >
              <button
                className="inline-flex items-center justify-between w-full pb-3 font-semibold text-left transition hs-accordion-toggle group gap-x-3 md:text-lg text-slate-800 hover:text-gray-500"
                aria-controls="hs-basic-with-title-and-arrow-stretched-collapse-five"
              >
                Does my institution need to implement the whole lifecycle if we
                buy greymatter?
                <svg
                  className="block w-3 h-3 text-gray-600 hs-accordion-active:hidden group-hover:text-gray-500"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2 5L8.16086 10.6869C8.35239 10.8637 8.64761 10.8637 8.83914 10.6869L15 5"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
                <svg
                  className="hidden w-3 h-3 text-gray-600 hs-accordion-active:block group-hover:text-gray-500"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2 11L8.16086 5.31305C8.35239 5.13625 8.64761 5.13625 8.83914 5.31305L15 11"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
              </button>
              <div
                id="hs-basic-with-title-and-arrow-stretched-collapse-five"
                className="hs-accordion-content hidden w-full overflow-hidden transition-[height] duration-300"
                aria-labelledby="hs-basic-with-title-and-arrow-stretched-heading-five"
              >
                <p className="text-base font-normal text-zinc-500">
                  greymatter can be configured and implemented in a phased
                  approach. Implementations typically start with a functional
                  area such as Recruit. From there, institutions can scale up to
                  another functional area to start building comprehensive data.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faq;
