import React from "react";

const ModalButton = ({ id }) => {
  return (
    <button data-hs-overlay={`#${id}`}>
      <p className="mt-5 inline-flex items-center gap-x-2 text-sm font-semibold text-[#456CCF]">
        Watch and learn more
        <svg
          width="15"
          height="14"
          viewBox="0 0 15 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_512_4784)">
            <path
              d="M7.09998 13.125C5.47552 13.125 3.91761 12.4797 2.76895 11.331C1.62029 10.1824 0.974976 8.62445 0.974976 7C0.974976 5.37555 1.62029 3.81763 2.76895 2.66897C3.91761 1.52031 5.47552 0.875 7.09998 0.875C8.72443 0.875 10.2823 1.52031 11.431 2.66897C12.5797 3.81763 13.225 5.37555 13.225 7C13.225 8.62445 12.5797 10.1824 11.431 11.331C10.2823 12.4797 8.72443 13.125 7.09998 13.125ZM7.09998 14C8.95649 14 10.737 13.2625 12.0497 11.9497C13.3625 10.637 14.1 8.85652 14.1 7C14.1 5.14348 13.3625 3.36301 12.0497 2.05025C10.737 0.737498 8.95649 0 7.09998 0C5.24346 0 3.46298 0.737498 2.15023 2.05025C0.837474 3.36301 0.0999756 5.14348 0.0999756 7C0.0999756 8.85652 0.837474 10.637 2.15023 11.9497C3.46298 13.2625 5.24346 14 7.09998 14Z"
              fill="#456CCF"
            />
            <path
              d="M5.5871 4.42305C5.65866 4.3862 5.739 4.36981 5.81928 4.37568C5.89955 4.38154 5.97666 4.40944 6.0421 4.4563L9.1046 6.6438C9.16132 6.68427 9.20754 6.7377 9.23944 6.79965C9.27133 6.86159 9.28797 6.93025 9.28797 6.99993C9.28797 7.0696 9.27133 7.13826 9.23944 7.20021C9.20754 7.26215 9.16132 7.31558 9.1046 7.35605L6.0421 9.54355C5.97668 9.59037 5.89962 9.61825 5.81938 9.62412C5.73914 9.62998 5.65884 9.61361 5.58731 9.57681C5.51577 9.54 5.45577 9.48418 5.4139 9.41548C5.37203 9.34679 5.34991 9.26788 5.34998 9.18743V4.81243C5.34989 4.732 5.37198 4.6531 5.41381 4.58441C5.45565 4.51572 5.5156 4.45989 5.5871 4.42305Z"
              fill="#456CCF"
            />
          </g>
          <defs>
            <clipPath id="clip0_512_4784">
              <rect
                width="14"
                height="14"
                fill="#456CCF"
                transform="translate(0.0999756)"
              />
            </clipPath>
          </defs>
        </svg>
      </p>
    </button>
  );
};

export default ModalButton;
