import React from "react";

const PromoTextCallout = () => {
  return (
    <div className="w-full h-[460px] flex p-6 sm:px-6 lg:px-8 mx-auto bg-[#C32027] rounded-md">
      <div className="flex flex-col md:w-9/12 lg:w-full mx-auto justify-center">
        <h2 className="text-2xl font-lato text-center text-white font-medium md:text-4xl md:leading-tight mb-3">
          Create 'wow' experiences with greymatter
        </h2>
        <p className="mt-6 text-base md:text-lg w-full font-lato font-normal text-center text-white">
          Get in touch to start empowering your teams and students.
        </p>
        <button
          type="button"
          className="bg-white mx-auto mt-10 py-3 px-4 w-32 inline-flex justify-center items-center gap-2 rounded-md font-semibold text-gray-800 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-blue-200 focus:ring-offset-2 transition-all text-base"
          data-hs-overlay="#contact-sales-modal"
        >
          Contact Sales
        </button>
      </div>
    </div>
  );
};

export default PromoTextCallout;
